<template>
  <component
    :is="node"
    class="button button-elem"
    :class="[iconClass, spinnerClass]"
    @click="$emit('click', $event)">
      <slot></slot>
      <spinner-elem v-if="spinner"></spinner-elem>
  </component>
</template>

<script>
export default {
  name: 'button-elem',
  props: {
    icon: {
      type: String,
      default: ''
    },
    node: {
      type: String,
      default: 'button'
    },
    spinner: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconClass () {
      if (fn.isString(this.icon)) {
        var cssClass = [
          'icon-' + this.icon
        ]
        if (!fn.isArray(this.$slots.default)) {
          cssClass.push('icon-only')
        }
        return cssClass.join(' ')
      } else {
        return ''
      }
    },
    spinnerClass () {
      if (this.spinner) {
        return 'is-disabled has-spinner'
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="sass">
.button-elem
</style>