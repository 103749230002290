<template>
  <vue-plyr class="video-elem">
    <div :data-plyr-provider="source" :data-plyr-embed-id="video"></div>
  </vue-plyr>
</template>

<script>

export default {
  name: 'video-elem',
  props: {
    source: {
      type: String,
      default: 'youtube'
    },
    video: {
      type: String,
      default: null
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="sass">
.video-elem
</style>
