<template>
  <segment-elem
    @click.native="clickHandler()"
    :class="{
      'is-visible': isVisible,
      'is-overlay-slider': isOverlaySlider
    }"
    class="overlay-segment">
  </segment-elem>
</template>

<script>
import dom from '@/services/DOM'

export default {
  name: 'overlay-segment',
  data () {
    return {
      isVisible: false,
      isOverlaySlider: false,
      clickEvent: null
    }
  },
  methods: {
    /**
     * clickEvent can be callback function or event-name
     */
    show (Event, clickEvent) {
      if (this.isVisible) {
        return
      }
      dom.fixBody()
      this.clickEvent = clickEvent
      this.isVisible = true
    },
    showSlider (Event, clickEvent) {
      this.isOverlaySlider = true
      this.show(Event, clickEvent)
    },
    hide () {
      if (!this.isVisible) {
        return
      }
      dom.releaseBody()
      this.isVisible = false
      this.clickEvent = null
      this.isOverlaySlider = false
    },
    clickHandler () {
      if (fn.isFunction(this.clickEvent)) {
        this.clickEvent.call()
      } else if(fn.isString(this.clickEvent)) {
        this.$trigger(this.clickEvent)
      }
    }
  },
  events: {
    'overlay/show': 'show',
    'overlay/show-slider': 'showSlider',
    'overlay/hide': 'hide'
  }
}
</script>

<style lang="sass">
.overlay-segment
  position: fixed
  visibility: hidden
  top: 0
  left: 0
  right: 0
  bottom: 0
  opacity: 0
  background-color: black()
  transition: opacity .5s ease
  z-index: $z-index-overlay
  &.is-overlay-slider
    z-index: $z-index-slider - 10
  &.is-visible
    visibility: visible
    opacity: .7
</style>
