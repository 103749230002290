<template>
  <segment-elem
    :class="{ 'is-visible': $store.getters['cookie/showConsentDialog']}"
    class="cookie-segment typo">
      <container-elem>
        <row-elem>
          <col-elem>
            <p>
              {{ $t('cookie.msg') }}<br />
              <link-elem :link="$store.state.site.pages.dataprotection">
                {{ $t('cookie.more') }}
              </link-elem>
            </p>
          </col-elem>
          <col-elem  class="buttons">
            <button-elem class="button is-primary" @click="ok($event)">{{ $t('cookie.ok') }}</button-elem>
          </col-elem>
        </row-elem>
      </container-elem>
  </segment-elem>
</template>

<script>

export default {
  name: 'cookie-segment',
  props: {},
  data() {
    return {}
  },
  methods: {
    more (Event) {

    },
    decline (Event) {
      this.$store.dispatch('cookie/setConsent', false)
    },
    ok (Event) {
      this.$store.dispatch('cookie/setConsent', true)
    }
  }
}
</script>

<style lang="sass">
.cookie-segment
  position: fixed
  display: flex
  flex-direction: column
  bottom: 0
  left: 0
  right: 0
  width: 100%
  padding: m(2)
  background-color: black(.7)
  transition: all .8s ease
  opacity: 0
  transform: translateY(100%)
  z-index: $z-index-modal + 10
  p
    +font('default')
    color: white()
  .buttons
    text-align: right
  &.is-visible
    opacity: 1
    transform: translateY(0)

+xs
  .cookie-segment
    .buttons
      text-align: center
</style>
