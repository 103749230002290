<template>
  <div class="segment debug-segment">
    <span class="width">{{ windowWidth }}px</span>
    <span class="breakpoint">{{ breakpoint }}</span>
  </div>
</template>

<script>
import DOM from '@/services/DOM'

export default {
  name: 'debug-segment',
  data: () => {
    return {
      windowWidth: null,
      breakpoint: null
    }
  },
  created () {
    this.windowWidth = DOM.getWindowWidth()
    this.setBreakpoint(null, DOM.getBreakpoint())
  },
  methods: {
    setWindowWidth (Event, width) {
      this.windowWidth = width
    },
    setBreakpoint (Event, breakpoint) {
      this.breakpoint = breakpoint
    }
  },
  events: {
    'window/window-width': 'setWindowWidth',
    'window/breakpoint-change': 'setBreakpoint'
  }
}
</script>

<style lang="sass">
.debug-segment
  position: fixed
  top: 0
  left: 0
  padding: 2px 10px
  color: white
  font-size: 14px
  background-color: red
  z-index: 1000
  .width
    padding-right: 10px
  .breakpoint
    display: inline-block

</style>