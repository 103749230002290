<template>
  <ul
    :class="elemClass"
    class="links-elem"
    ref="links">
      <li
        v-for="(link, i) in links"
        :key="i"
        :class="itemClass(link)"
        @mouseenter="mouseEnterHandler($event, link)"
        @mouseleave="mouseLeaveHandler($event, link)"
        class="item">
          <link-elem
            :link="link"
            @click="clickHandler($event, link)"
            :class="linkClass">
              {{ link.title }}
          </link-elem>
          <links-elem
            v-if="hasChildren(link)"
            :links="link.children"
            :maxLevel="maxLevel"
            :currentLevel="currentLevel + 1"
            :dropdownStatus="dropdownStatus"
            :class="{ 'is-left': dropdownPosition === 'left', 'is-right': dropdownPosition === 'right' }"
            @mouseenter="mouseEnterHandler"
            @mouseleave="mouseLeaveHandler"
            @click="clickHandler">
          </links-elem>
      </li>
  </ul>
</template>

<script>
import dom from '@/services/DOM'

export default {
  name: 'links-elem',
  props: {
    links: {
      type: Array,
      default: () => {
        return []
      }
    },
    maxLevel: {
      type: Number,
      default: 1
    },
    currentLevel: {
      type: Number,
      default: 1
    },
    dropdownStatus: {
      type: Object,
      default () {
        return {}
      }
    },
    dropdownPosition : {
      type: String,
      default: 'left'
    },
    linkClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    elemClass () {
      return 'links-elem-level-' + this.currentLevel
    }
  },
  methods: {
    isPage (link) {
      return link.type === 'page'
    },
    isGroup (link) {
      return link.type === 'group'
    },
    hasChildren (link) {
      return this.isGroup(link) && this.currentLevel < this.maxLevel && link.children && link.children.length > 0
    },
    itemClass (link) {
      var res =  ['is-' + link.type]
      if (link.home) {
        res.push('is-home')
      }
      if ((this.isPage(link) || this.isGroup(link)) && fn.has(link, 'value')) {
        res = res.concat(dom.getActiveClasses(this.$route.path, link.value))
      }
      if (this.hasChildren(link)) {
        res.push('has-dropdown')
      }
      if (this.isGroup(link) && this.dropdownStatus[link.id]) {
        res.push('is-open')
      }
      if (fn.has(link, 'cssclass') && fn.isString(link.cssclass)) {
        res.push(link.cssclass)
      }
      return res.join(' ')
    },
    getWidth () { // used by parent
      return dom.getWidth(this.$refs.links)
    },
    mouseEnterHandler (Event, link) {
      Event.stopPropagation()
      this.$emit('mouseenter', Event, link)
    },
    mouseLeaveHandler (Event, link) {
      Event.stopPropagation()
      this.$emit('mouseleave', Event, link)
    },
    clickHandler (Event, link) {
      this.$emit('click', Event, link)
    }
  }
}
</script>

<style lang="sass">
// Minimum style for classes is-vertical and is-horizontal
// usually styled by parent component
.links-elem
  &.is-vertical
    .links-elem-level-2
      .item
        margin-left: m(3)
  &.is-horizontal
    display: flex
    .item
      margin-right: m(1)
      &:last-child
        margin-right: 0
    .links-elem-level-2
      display: none
</style>