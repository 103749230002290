<template>
  <segment-elem
    component="footer"
    class="footer-segment"
    ref="footer">
      <container-elem>
        <row-elem>
          <col-elem>
            {{ $store.state.site.content.copyright | replace('#year', year) }}
          </col-elem>
          <col-elem>
            <links-elem
              :links="this.$store.state.navigation.navmeta"
              :maxLevel="1"
              class="nav-footer" />
          </col-elem>
        </row-elem>
      </container-elem>
  </segment-elem>
</template>

<script>
export default {
  name: 'footer-segment',
  methods: {
    year () {
      let Now = new Date()
      return Now.getFullYear()
    }
  }
}
</script>

<style lang="sass">
.footer-segment
  z-index: $z-index-base
  background-color: $body-color
  margin-top: m(3)
  .nav-footer
    display: flex
    justify-content: flex-end
    margin-left: - m(1)
    margin-right: - m(1)
    li
      margin: 0 m(1)
      a
        color: $primary-color
        &:hover
          text-decoration: underline

+xs
  .footer-segment
    .col
      display: flex
      justify-content: center
      padding: 0
    .nav-footer
      justify-content: center
      margin: 0
</style>