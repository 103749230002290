<template>
  <component
    :is="component"
    class="container">
      <slot></slot>
  </component>
</template>

<script>

export default {
  name: 'container-elem',
  props: {
    'component': {
      type: String,
      default: 'div'
    }
  }
}
</script>

<style lang="sass">
.container
</style>
