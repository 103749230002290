<template>
  <div class="navigation-elem" :class="displayClass" ref="nav">
    <div
      v-if="isOffCanvas"
      v-bind:class="{ 'is-open' : sliderIsOpen }"
      class="nav-toolbar"
      ref="navbar">
        <button-elem
          v-if="isOffCanvas"
          @click="clickMenuButtonHandler($event)"
          icon="menu"
          class="is-bigger is-icon is-narrow is-white move-down menu-button">
        </button-elem>
    </div>
    <nav
      class="nav-slider"
      :class="{ 'is-open' : sliderIsOpen }"
      @click="clickSliderHandler($event)"
      v-touch:swipe.right="onSwipeSlider">
        <div class="nav-viewbox">
          <button-elem
            v-if="isOffCanvas"
            @click="clickCloseButtonHandler($event)"
            icon="tk-close"
            class="is-bigger is-icon is-narrow is-primary close-button">
          </button-elem>
          <div class="main-group">
            <links-elem
              :links="navMainAdapted"
              :maxLevel="2"
              :dropdownStatus="dropdownStatus"
              :dropdownPosition="dropdownPosition"
              @mouseenter="mouseEnterHandler"
              @mouseleave="mouseLeaveHandler"
              @click="clickHandler"
              class="nav-main"
              ref="main" />
            <div class="item-lang">
              <language-select-elem v-if="$store.getters.isMultiLanguage" />
            </div>
          </div>
        </div>
    </nav>
  </div>
</template>

<script>
import dom from '@/services/DOM'
import { breakpoints } from '@/config/style.json'
import LanguageSelectElem from '@/components/elements/LanguageSelect'

export default {
  name: 'navigation-elem',
  components: {
    LanguageSelectElem
  },
  data () {
    return {
      offCanvasBreakpoint: 'sm', // breakpoint, where off-canvas is always true
      navWidth: 0, // width of on-canvas navigation
      isOffCanvas: false,
      sliderIsOpen: false,
      currentUri: null,
      dropdownStatus: {},
      dropdownPosition: 'left', // TODO: set dynamically
      toolbarTop: 0,
      init: false
    }
  },
  created () {
    this.dropdownStatus = this.getDropdownsStatus(this.$store.state.navigation.navmain)
  },
  mounted () {

    // get breakpoint width, because we work with pixel, not with breakpoint-names
    if (fn.isString(this.offCanvasBreakpoint) && fn.has(breakpoints, this.offCanvasBreakpoint)) {
      this.offCanvasBreakpoint = breakpoints[this.offCanvasBreakpoint]
    }

    // for check, if navigation fit's in viewport, change or set to 0 to disable
    this.navWidth = this.$refs.main.getWidth()

    // set off- or on-canvas classes
    this.setDisplayType(dom.getWindowWidth())

    // navigation is invisible up to this point
    this.$nextTick(() => {
      this.init = true
    })
  },
  computed: {
    navMainAdapted () {
      var res = fn.clone(this.$store.state.navigation.navmain)

      // only one level!
      fn.each(res, (entry) => {
        if (fn.has(entry, 'pagetype')) {
          if (entry.pagetype === 'configurator') {
            var children = []
            fn.each(this.$store.getters['products/available']('array'), (product) => {
              children.push({
                name: 'configurator',
                params: {
                  product: product
                },
                title: this.$store.state.site.products[product]
              })
            })
            entry.cssclass = 'is-hidden-oncanvas'
            entry.children = children
          } else if (entry.pagetype === 'wizard') {
            entry.type = 'page'
            entry.value = entry.redirect
            entry.cssclass = 'is-hidden-oncanvas'
          }
        }
      })
      return res
    },
    displayClass () {
      var res = []
      if (this.isOffCanvas) {
        res.push('off-canvas')
        if (this.sliderIsOpen) {
          res.push('is-open')
        }
      } else {
        res.push('on-canvas')
        if (!this.init) {
          res.push('on-init')
        }
      }
      return res.join(' ')
    },
    menuIcon () {
      if (this.sliderIsOpen) {
        return 'close'
      } else {
        return 'menu'
      }
    }
  },
  methods: {
    getDropdownsStatus (links) {
      var res = {}
      fn.each(links, (link) => {
        if (link.type === 'group') {
          res[link.id] = this.isOffCanvas && dom.isActiveUri(this.$route.path, link.value)
          if (link.children && link.children.length > 0) {
            res = fn.assign(res, this.getDropdownsStatus(link.children))
          }
        }
      })
      return res
    },
    setDisplayType (windowWidth) {

      // off bei definition
      var isOffCanvas = windowWidth <= this.offCanvasBreakpoint

      // off because nav doesn't fit in viewbox
      if (!isOffCanvas && this.navWidth > 0) {
        isOffCanvas = dom.getWidth(this.$refs.nav) < this.navWidth
      }
      if (isOffCanvas !== this.isOffCanvas) {
        this.isOffCanvas = isOffCanvas
        if (this.sliderIsOpen) {
          this.sliderIsOpen = false
          this.$trigger('overlay/hide')
        }
        this.dropdownStatus = this.getDropdownsStatus(this.$store.state.navigation.navmain)
      }
    },
    openSlider () {
      this.sliderIsOpen = true
      this.$trigger('overlay/show-slider', 'overlay/click')
    },
    closeSlider () {
      this.sliderIsOpen = false
      this.$trigger('overlay/hide')
    },
    setToolbarPosition (params) {
      if (!this.$refs.navbar) {
        return
      }
      var dim = dom.getDimensions(this.$refs.navbar)

      // start position from top, where navbar begins to hide
      var start = dim.height * 2
      var top = this.toolbarTop + params.diff
      if (start >= params.top || top < 0) {
        top = 0
      } else if (top > dim.height) {
        top = dim.height
      }
      if (top !== this.toolbarTop) {
        this.toolbarTop = top
        dom.setStyle(this.$refs.navbar, 'top', '-' + top + 'px')
      }
    },
    clickMenuButtonHandler (Event) {
      Event.stopPropagation()
      if (!this.sliderIsOpen) {
        this.openSlider()
      }
    },
    clickCloseButtonHandler (Event) {
      Event.stopPropagation()
      if (this.sliderIsOpen) {
        this.closeSlider()
      }
    },
    openDropdown (link, closeOther) {
      if (closeOther) {
         fn.each(this.dropdownStatus, (dropdown, id) => {
          this.dropdownStatus[id] = (id === link.id)
        })
      } else {
        this.dropdownStatus[link.id] = true
      }
    },
    closeDropdown (link) {
      if (!link) {
        fn.each(this.dropdownStatus, (dropdown, id) => {
          this.dropdownStatus[id] = false
        })
      } else {
        this.dropdownStatus[link.id] = false
      }
    },
    toggleDropdown (link, closeOther) {
      if (this.dropdownStatus[link.id]) {
        this.closeDropdown(link)
      } else {
        this.openDropdown(link, closeOther)
      }
    },
    mouseEnterHandler (Event, link) {
      if (!this.$isTouch() && !this.isOffCanvas && link.type === 'group') {
        this.openDropdown(link)
      }
    },
    mouseLeaveHandler (Event, link) {
      if (!this.$isTouch() && !this.isOffCanvas && link.type === 'group') {
        this.closeDropdown(link)
      }
    },
    clickHandler (Event, link) {

      // off-canvas
      Event.stopPropagation()
      if (this.isOffCanvas) {
        if (link.type === 'group') {
          this.toggleDropdown(link)
        } else if (link.type === 'scroll') {
          this.closeSlider()
        } else {
          this.closeSlider()
        }
      }
      
      // on-canvas
      else {
        if (link.type === 'group') {
          if (this.$isTouch()) {
            this.toggleDropdown(link, true)
          } else {
            this.closeDropdown()
            this.$router.push(link.redirect).catch(err => {})
          }
        } else {
          this.closeDropdown()
        }
      }
    },
    clickSliderHandler (Event) {
      Event.stopPropagation()
    },
    onSwipeSlider () {
      this.closeSlider()
    },
    onWindowScroll (Event, params) {
      this.setToolbarPosition(params)
    },
    onWindowWidth (Event, width) {
      this.setDisplayType(width)
    }
  },
  events: {
    'overlay/click': 'closeSlider',
    'window/window-width': 'onWindowWidth',
    'window/scroll': 'onWindowScroll'
  }
}
</script>

<style lang="sass">

// on-canvas
$nav-main-color:                 white()
$nav-main-hover-color:           white()
$nav-main-active-color:          white()

$nav-meta-color:                 grey(5)
$nav-meta-hover-color:           $link-active-color
$nav-meta-active-color:          $link-active-color

// dropdowns on-canvas navigation
$nav-dropdown-color:             grey(4)
$nav-dropdown-bg-color:          grey(7)
$nav-dropdown-hover-color:       darken(grey(7), 5%)

// for off-canvas slider
$nav-slider-bg-color:            white()
$nav-slider-border-color:        grey(7)

$nav-slider-main-bg-color:       white()
$nav-slider-main-color:          $primary-color
$nav-slider-main-hover-color:    $primary-color
$nav-slider-main-active-color:   $primary-color

$nav-slider-meta-bg-color:       white()
$nav-slider-meta-color:          grey(3)
$nav-slider-meta-hover-color:    grey(3)
$nav-slider-meta-active-color:   grey(3)

// the off-canvas open button
$nav-button-bg-color:            $content-color
$nav-button-closed-color:        grey(5) // closed status
$nav-button-open-color:          grey(5) // open status

.navigation-elem
  &.on-init
    visibility: hidden
  &.on-canvas
    position: relative
    .nav-slider
      .nav-viewbox
        display: flex
        flex-direction: row-reverse
        justify-content: space-between
        .main-group
          display: flex
          justify-content: center
          margin-left: - m(1)
          margin-right: - m(1)
          .links-elem-level-1
            position: relative
            display: flex
            line-height: 1
            >.item
              display: flex
              flex-direction: column
              padding: 0 m(1)
              >.link
                display: flex
                align-items: center
                position: relative
                +font('semibold')
                line-height: 2
                color: $nav-main-color
                white-space: nowrap
                &:hover
                  color: $nav-main-hover-color
                  text-decoration: underline
              &.is-hidden-oncanvas
                display: none
              &.has-dropdown
                .links-elem-level-2
                  display: flex
                  visibility: hidden
                  position: absolute
                  flex-direction: column
                  top: 100%
                  padding: m(1) 0
                  background-color: $nav-dropdown-bg-color
                  z-index: $z-index-base + 10
                  +soft-shadow
                  &:before
                    position: absolute
                    top: -5px
                    content: ""
                    border-bottom: 5px solid $nav-dropdown-bg-color
                    border-left: 5px solid transparent
                    border-right: 5px solid transparent
                  &.is-left
                    margin-left: 0
                    &:before
                      left: m(4)
                  &.is-right
                    align-self: flex-end
                    margin-right: - m(1)
                    &:before
                      right: m(4)
                  >.item
                    padding: 0
                    >.link
                      display: flex
                      padding: m(1) m(2)
                      color: $nav-dropdown-color
                      white-space: nowrap
                      &:hover
                        color: $nav-dropdown-color
                        background-color: $nav-dropdown-hover-color
                    &.is-active
                      >.link
                        background-color: $nav-dropdown-hover-color
              &.is-open // dropdown is open
                &.is-active
                  &:after
                    background-color: transparent
                .links-elem-level-2
                  visibility: visible
          .item-lang
            .language-select-elem
              display: inline-block
              margin-left: m(1)
              margin-right: m(1)
              .select
                width: auto
                padding: 0
                +font('default', 'semibold')
                line-height: 2
                color: $nav-main-color
                border: none
                background-color: transparent
                .dropdown-icon
                  border-color: $nav-main-color
                  border-left-width: 3px
                  border-bottom-width: 3px
                  margin-top: -4px
  
  &.off-canvas
    .nav-toolbar
      display: inline-block
      width: auto
      padding: 0
      background-color: transparent
      line-height: 1
    .nav-slider
      $min-width: map-get($breakpoints, 'xs') * .75
      visibility: hidden
      position: fixed
      top: 0
      right: 0
      height: 100%
      width: auto
      min-width: #{$min-width}px
      max-width: 100%
      background-color: $nav-slider-bg-color
      z-index: $z-index-slider + 10
      transform: translateX(100%)
      transition: transform .5s ease 0s, visibility 0s linear .5s
      +soft-shadow
      .nav-viewbox
        display: flex
        flex-direction: column
        height: 100%
        overflow-x: hidden
        overflow-y: scroll
        -webkit-overflow-scrolling: touch
        .close-button
          flex-shrink: 2
          align-self: flex-start
          margin: m(.5) m(2) 0 m(2)
        .item
          .link
            display: block
            position: relative
            padding: m(.5) m(4)
            cursor: pointer
            +ellipsis
          &.has-dropdown
            >.link
              display: flex
              flex-direction: row-reverse
              justify-content: flex-end
              align-items: center
              +icon('down')
              &:before
                margin-left: 4px
                font-size: .6em
            .links-elem-level-2
              visibility: hidden
              height: 0
              .item
                .link
                  padding-left: m(6)
            &.is-open
              .links-elem-level-2
                visibility: visible
                height: 100%
        .main-group
          display: flex
          flex-direction: column
          border-bottom: 1px solid $nav-slider-border-color
          .links-elem-level-1
            background-color: $nav-slider-main-bg-color
            .item
              line-height: 2
              border-top: 1px solid $nav-slider-border-color
              .link
                color: $nav-slider-main-color
                &:hover
                  color: $nav-slider-main-hover-color
                  text-decoration: underline
              >.link
                &:before
                  transform: rotate(-90deg)
                  transition: transform .1s linear
              &.is-open
                >.link
                  &:before
                    transform: translateY(2px) rotate(0deg)
              &.is-active
                >.link
                  color: $nav-slider-main-active-color
                &.is-open
                  >.link
                    color: $nav-slider-main-color
          .item-lang
            padding-top: 10px
            padding-bottom: 0
            border-top: 1px solid $nav-slider-border-color
            .language-select-elem
              display: inline-block
              margin-left: m(4)
              margin-bottom: m(1)
              .select
                width: auto
                padding: 0
                +font('default', 'regular')
                color: $nav-slider-main-color
                border: none
                background-color: transparent
                .dropdown-icon
                  border-color: $nav-slider-main-color
                  border-left-width: 3px
                  border-bottom-width: 3px
                  margin-top: -4px
      &.is-open
        visibility: visible
        transform: translateX(0)
        transition: transform .5s ease 0s

</style>