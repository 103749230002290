<template>
  <segment-elem class="content-segment">
    <transition name="fade" mode="out-in">
      <router-view class="page"></router-view>
    </transition>
  </segment-elem>
</template>

<script>
import Loader from '@/services/Loader'

export default {
  name: 'content-segment'
}
</script>

<style lang="sass">
.content-segment
  .page
    padding-bottom: m(2)
    transition: all .4s cubic-bezier(.55,0,.1,1)
    &.fade-enter-active,
    &.fade-leave-active
      transition: opacity .5s ease
    &.fade-enter,
    &.fade-leave-active
      opacity: 0
</style>
