<template>
  <segment-elem
    component="header"
    class="header-segment">
      <container-elem>
        <link-elem :link="{ name: 'home' }" class="link-home">
          <div class="logo">
            <img class="is-hidden-xs" src="https://d13qmi8c46i38w.cloudfront.net/media/UCPthyssenkruppAG/assets.files/media/logo-(header)/thyssenkrupp_logo_claim_d.svg" :alt="$store.state.site.meta.title"/>
            <img class="is-xs-only" src="https://d13qmi8c46i38w.cloudfront.net/media/UCPthyssenkruppAG/assets.files/media/logo-(header)/thyssenkrupp_logo_m.svg" :alt="$store.state.site.meta.title"/>
          </div>
        </link-elem>
        <navigation-elem></navigation-elem>
        <div class="tool">
          <span class="division">{{ $store.state.site.content.division }}</span>
          <span class="toolname">{{ $store.state.site.content.toolname }}</span>
        </div>
      </container-elem>
  </segment-elem>
</template>

<script>
import NavigationElem from '@/components/elements/Navigation'

export default {
  name: 'header-segment',
  components: {
    NavigationElem
  }
}
</script>

<style lang="sass">
.header-segment
  background-color: $primary-color
  .container
    position: relative
    padding-top: 0
    padding-bottom: 0
    background-color: $primary-color
    .link-home
      display: inline-block
      height: 100%
    .logo
      display: inline-block
      padding-top: 35px
      padding-bottom: 35px
      img
        height: 110px
        cursor: pointer
    .navigation-elem
      position: absolute
      top: 28px
      left: content-padding()
      right: content-padding()
      margin-top: 0
    .tool
      position: absolute
      display: flex
      flex-direction: column
      align-items: flex-end
      justify-content: flex-end
      bottom: 35px
      left: content-padding()
      right: content-padding()
      .division
        color: white()
        font-size: 1.25rem
        line-height: 1.4
      .toolname
        padding-bottom: 10px
        color: white()
        font-size: 1.75rem
        line-height: 1

+md
  .header-segment
    .container
      .navigation-elem
        left: content-padding('md')
        right: content-padding('md')
      .tool
        left: content-padding('md')
        right: content-padding('md')

+sm
  .header-segment
    .container
      .navigation-elem
        left: auto
        top: 29px
        right: content-padding('sm')
      .logo
        padding-top: 29px
        padding-bottom: 29px
        img
          height: 90px
      .tool
        display: none

+xs
  .header-segment
    .container
      .navigation-elem
        left: auto
        top: auto
        right: content-padding('xs')
        bottom: 7px
      .logo
        padding-top: 62px
        padding-bottom: 11px
        img
          height: 32px

</style>