<template>
  <div class="language-select-elem">
    <select-elem
      v-model="value"
      :options="options"
      @input="onChange()" />
  </div>
</template>

<script>
import SelectElem from '@/components/controls/Select'

export default {
  name: 'language-select-elem',
  components: {
    SelectElem
  },
  data () {
    return {
      value: this.$store.state.lang
    }
  },
  computed: {
    options () {
      var res = []
      fn.each(this.$store.state.languages, (language, key) => {
        res.push({
          key: key,
          label: language.name
        })
      })
      return res
    }
  },
  methods: {
    onChange () {
      if (this.value === this.$store.state.lang) {
        return
      }
      this.$store.dispatch('loadLanguage', this.value)
      .then(() => {
        
        // this doesn't work, when languages have different routes/slugs!!
        // use '/' + this.$store.state.page.head.translations[this.value] in that case
        this.$router.push({ params: { lang: this.value } })
          .catch(err => {})
      })
    }
  }
}
</script>

<style lang="sass">
.language-select-elem
</style>