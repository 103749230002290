<template>
  <segment-elem
    :class="{
      'is-visible': isVisible,
      'is-fullscreen': isFullscreen,
      'is-dialog': !isFullscreen,
      'is-error': isError
    }"
    class="dialog-segment">
      <div v-if="isVisible" class="viewbox">
        <div v-if="!isModal" class="close">
          <div class="close-btn" @click="cancelHandler">
            <span></span>
            <span></span>
          </div>
        </div>
        <div class="body text">
          <component :is="bodyComponent" :content="params.body" />
        </div>
        <div
          v-if="hasSpinner"
          :class="{ 'has-actions': hasActions }"
          class="spinner">
            <spinner-elem :delay="false" />
        </div>
        <div v-if="hasActions" class="actions">
          <button-elem
            v-if="hasActionCancel"
            @click="cancelHandler"
            class="is-text">
              {{ params.actions.cancel.label || 'Cancel' }}
          </button-elem>
          <button-elem
            v-if="hasActionOther"
            @click="otherHandler"
            class="is-text">
              {{ params.actions.other.label || 'undefined' }}
          </button-elem>
          <button-elem
            v-if="hasActionSubmit"
            @click="submitHandler"
            class="is-tool is-primary">
              {{ params.actions.submit.label || 'Submit' }}
          </button-elem>
        </div>
      </div>
  </segment-elem>
</template>

<script>
import Scroll from '@/services/Scroll'

export default {
  name: 'dialog-segment',
  props: {},
  data () {
    return {
      /**
       * {
       *   text: 'The message text',
       *   fullscreen: bool // fullscreen display, for longer texts
       *   modal: bool // no close button, no click on overlay,
       *   spinner: bool // show spinner
       *   error: error styling
       *   actions: {
       *     submit: {
       *       label: 'Button label'.
       *       event: optional,
       *       params: optional event params
       *     },
       *     other: {
       *       label: 'Button label'.
       *       event: optional,
       *       params: optional event params
       *     },,
       *     cancel: {
       *       label: 'Button label'.
       *       event: optional,
       *       params: optional event params
       *     }
       *   }
       * }
       */
      params: {},
      text: null,
      isVisible: false
    }
  },
  computed: {
    isModal () {
      return fn.has(this.params, 'modal') && fn.isTrue(this.params.modal)
    },
    isFullscreen () {
      return fn.has(this.params, 'fullscreen') && fn.isTrue(this.params.fullscreen)
    },
    isError () {
      return fn.has(this.params, 'error') && fn.isTrue(this.params.error)
    },
    hasSpinner () {
      return fn.has(this.params, 'spinner') && fn.isTrue(this.params.spinner)
    },
    hasActions () {
      return fn.isObject(this.params.actions)
    },
    hasActionCancel () {
      return this.hasActions && fn.isObject(this.params.actions.cancel)
    },
    hasActionOther () {
      return this.hasActions && fn.isObject(this.params.actions.other)
    },
    hasActionSubmit () {
      return this.hasActions && fn.isObject(this.params.actions.submit)
    },
    bodyComponent () {
      return fn.isArray(this.params.body) ? 'sections-elem' : 'text-elem'
    }
  },
  methods: {
    show (Event, params) {
      this.params = params
      this.isVisible = true
      document.activeElement.blur()
      this.$trigger('overlay/show', 'modal/hide')
    },
    hide () {
      this.isVisible = false
      this.params = {}
      this.$trigger('overlay/hide')
    },
    cancelHandler () {
      if (this.isVisible) {
        if (this.hasActionCancel && fn.isString(this.params.actions.cancel.event)) {
          this.$trigger(this.params.actions.cancel.event, (this.params.actions.cancel.params || {}))
        }
        this.hide()
      }
    },
    otherHandler () {
      if (this.isVisible) {
        if (this.hasActionOther && fn.isString(this.params.actions.other.event)) {
          this.$trigger(this.params.actions.other.event, (this.params.actions.other.params || {}))
        }
        this.hide()
      }
    },
    submitHandler () {
      if (this.isVisible) {
        if (this.hasActionSubmit && fn.isString(this.params.actions.submit.event)) {
          this.$trigger(this.params.actions.submit.event, (this.params.actions.submit.params || {}))
        }
        this.hide()
      }
    }
  },
  events: {
    'modal/show': 'show',
    'modal/hide': 'hide',
    'key/esc': 'cancelHandler',
    'key/enter': 'submitHandler'
  }
}
</script>

<style lang="sass">
$dialog-width: 620px
.dialog-segment
  position: fixed
  display: none
  flex-direction: column
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: $z-index-modal
  .close-btn
    position: relative
    display: inline-block
    width: 30px
    height: 30px
    background-color: white()
    border-radius: 100%
    border: 1px solid grey(5)
    cursor: pointer
    span
      position: absolute
      display: block
      top: calc(50% - 2px)
      left: 10%
      height: 4px
      width: 80%
      background-color: grey(5)
      border-radius: 2px
      &:nth-child(1)
        transform: rotate(45deg)
      &:nth-child(2)
        transform: rotate(-45deg)
    &:focus,
    &:active
      border-color: grey(4)
      span
        background-color: grey(4)
  &.is-dialog
    justify-content: center
    .viewbox
      display: flex
      flex-direction: column
      flex-shrink: 1
      max-width: $dialog-width
      margin: 0 auto
      background-color: white()
      .close
        position: relative
        display: flex
        justify-content: flex-end
        line-height: 0
        .close-btn
          position: absolute
          transform: translate(10px, -10px)
      .body
        padding: m(2)
        text-align: center
        +font('tool')
        *
          +font('tool')
      .spinner
        line-height: 0
        padding-bottom: m(3)
        &.has-actions
          padding-bottom: m(1)
      .actions
        display: flex
        justify-content: center
        padding-bottom: m(2)
        .button-elem
          margin: 0 m(1)
  &.is-fullscreen
    justify-content: flex-start
    .viewbox
      position: relative
      max-width: content-width('sm')
      height: 100%
      max-height: 100%
      margin: 0 auto
      background-color: white()
      overflow-y: scroll
      -webkit-overflow-scrolling: touch
      .close
        position: relative
        display: flex
        justify-content: flex-end
        line-height: 0
        padding: m(2) m(2) 0 0
        .close-btn
          position: fixed
      .body
        padding: m(4) content-padding('md')
      .spinner
        padding: m(4) content-padding('md')
        line-height: 0
        padding-bottom: m(3)
        &.has-actions
          padding-bottom: m(1)
      .actions
        display: flex
        justify-content: center
        padding: m(4) content-padding('md')
        .button-elem
          margin: 0 m(1)
  &.is-error
    .viewbox
      border-top: 10px solid $warning-color
      .close
        .close-btn
          transform: translate(10px, -24px)
  &.is-visible
    display: flex

+sm
  .dialog-segment
    &.is-fullscreen
      .viewbox
        .body,
        .actions
          padding: m(4) content-padding('sm')

+xs
  .dialog-segment
    &.is-fullscreen
      .viewbox
        .body,
        .actions
          padding: m(4) content-padding('xs')

+until($dialog-width)
  .dialog-segment
    &.is-dialog
      justify-content: flex-start
      background-color: white
      .viewbox
        .close
          padding: m(2) m(2) 0 0
          .close-btn
            position: relative
            transform: none
</style>
