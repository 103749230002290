<template>
  <picture class="image-elem" @click="$emit('click')">
    <source v-bind:srcset="srcset('xs')" v-bind:media="breakpoint('xs')">
    <source v-bind:srcset="srcset('sm')" v-bind:media="breakpoint('sm')">
    <source v-bind:srcset="srcset('md')" v-bind:media="breakpoint('md')">
    <img v-bind:srcset="srcset('lg')" v-bind:alt="alt">
  </picture>
</template>

<script>
import DOM from '@/services/DOM'
import { breakpoints } from '@/config/style.json'

export default {
  name: 'image-elem',
  props: {

    // the image object
    file: {
      type: Object
    },

    // mixed - either a node from images.json or
    // the array with the scaling config
    size: {}
  },
  computed: {
    alt () {
      var alt = fn.path(this.file, 'content.alt')
      return fn.escape(alt)
    }
  },
  methods: {
    breakpoint(breakpoint) {
      return '(max-width: ' + breakpoints[breakpoint] + 'px)'
    },

    // TODO: implement size-array with breakpoints like in images.json
    // TODO: don't set multiple srcset with same dimensions (if so)
    srcset(breakpoint) {
      var res = []
      var config = this.size
      if(fn.isString(this.size)) {
        config += '.' + breakpoint
      }
      var image = DOM.getImage(this.file, config)
      if (fn.isObject(image)) {
        res.push(image.url)
      }
      image = DOM.getImage(this.file, config, true)
      if (fn.isObject(image)) {
        res.push(image.url + ' 2x')
      }
      return res.join(', ')
    }
  }
}
</script>

<style lang="sass">
.image-elem
  // no display: flex !!
  font-size: 0
  line-height: 0
  &.is-hero
    display: flex
    width: 100%
    height: 100%
    overflow: hidden
    img
      flex: 1
      font-family: "object-fit:cover;object-position:50% 50%"
      object-fit: cover
      object-position: 50% 50%
      width: 100%
      height: auto
</style>