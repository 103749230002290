<template>
  <a
    :href="href"
    :target="target"
    @click="clickHandler($event)"
    class="link">
      <slot>{{ title }}</slot>
  </a>
</template>

<script>
import scroll from '@/services/Scroll'

export default {
  name: 'link-elem',
  props: {
    link: null, // string or router object or object {value, redirect, type, title, anchor, params)
    replace: { // use $router.replace() instead of $router.push()
      type: Boolean,
      default: false
    }
  },
  computed: {
    href () {
      if (fn.isObject(this.link)) {
        
        // kirby object
        if (fn.has(this.link, 'type')) {
          switch (this.link.type) {
            case 'page':
              if (fn.has(this.link, 'redirect')) {
                return this.link.redirect
              } else {
                return this.link.value
              }
            case 'extern':
              return this.link.value
            case 'email':
            case 'file':
            case 'tel':
              return this.link.value
          }
        }

        // router object https://router.vuejs.org/guide/essentials/navigation.html
        else if (this.isRouterLink) {
          var route = this.$router.resolve(this.link)
          return route.href || '/'
        }
      }

      // extern url
      else if (fn.isUrl(this.link)) {
        return this.link
      }

      // email
      else if (fn.isEmail(this.link)) {
        return 'mailto:' + this.link
      }
      return '/'
    },
    target () {
      if ((fn.isObject(this.link) && this.link.type === 'extern') || fn.isUrl(this.link)) {
        return '_blank'
      }
      return ''
    },
    title () {
      if (fn.isObject(this.link)) {
        return this.link.title
      } else if (fn.isEmail(this.link)) {
        return this.link
      }
      return this.href
    },
    anchor () {
      if (fn.isObject(this.link) && this.link.type === 'anchor') {
        return this.link.value
      }
      return null
    },
    isPage () {
      if ((fn.isObject(this.link) && this.link.type === 'page') || fn.isString(this.link)) {
        return true
      }
      return false
    },
    isRouterLink () {
      return fn.has(this.link, 'path') || fn.has(this.link, 'name') || fn.has(this.link, 'params') || fn.has(this.link, 'query')
    }
  },
  methods: {
    clickHandler (Event) {
      Event.stopPropagation()
      if (this.isRouterLink) {
        Event.preventDefault()
        this.goToRoute(this.link)
      } else if (this.isPage) {
        Event.preventDefault()
        this.goToRoute(this.href)
      } else if (this.anchor) {
        this.scrollTo(this.anchor)
      }
      this.$emit('click', Event, this.link)
    },
    scrollTo (anchor) {
      if (fn.has(this.$store.state.elements, anchor)) {
        scroll.smooth(this.$store.state.elements[anchor])
      }
    },
    goToRoute (target) {
      if (this.replace) {
        this.$router.replace(target).catch(err => {})
      } else {
        this.$router.push(target).catch(err => {})
      }
    }
  }
}
</script>

<style lang="sass">
.link-elem
  cursor: pointer
</style>