const cacheStore = {
  namespaced: true,

  /*
  |--------------------------------------------------------------------------
  | State
  |--------------------------------------------------------------------------
  */
  state: {
    cache: new Map()
  },

  /*
  |--------------------------------------------------------------------------
  | Getters
  |--------------------------------------------------------------------------
  */

  getters: {
    get: (state) => (url) => {
      let hash = fn.hash(url)
      if (state.cache.has(hash)) {
        info('get data for ' + url + ' from cache')
        return state.cache.get(hash)
      } else {
        return null
      }
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Mutations
  |--------------------------------------------------------------------------
  */

  mutations: {
    set (state, params) {
      info('cache data for ' + params.url)
      let hash = fn.hash(params.url)
      state.cache.set(hash, params.data)
      if (state.cache.size > fn.toInteger(process.env.VUE_APP_CACHESIZE)) {
        let first =  state.cache.keys().next().value
        state.cache.delete(first)
      }
    }
  }
}

export default cacheStore