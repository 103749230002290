<template>
  <div
    :class="{
      'has-error': hasError,
      'is-disabled': disabled,
      'has-help': hasHelp,
      'is-blinking': isBlinking
    }"
    class="control select select-control">
      <span class="selected-option">
        {{ selectedLabel || "&nbsp;" }}
      </span>
      <span class="dropdown-icon"></span>
      <select
        v-model="inputValue"
        :disabled="disabled"
        @change="changeHandler($event)">
          <option
            v-for="(option) in options"
            :key="option.key"
            :value="option.key"
            :disabled="option.disabled">
              {{ label(option) }}
          </option>
      </select>
      <span v-if="hasHelp" class="help">
        {{ help }}
      </span>
  </div>
</template>

<script>
import DOM from '@/services/DOM'

export default {
  name: 'select-control',
  props: {
    value: {
      default: ''
    },

    /**
     * optional store action/mutation on value change
     * { action: foo, mutation: bar [, more: callback-params]}
     * otherwise value will be emitted via v-model
     */ 
    callback: {
      type: Object
    },
    options: {
      type: Array // with key & disabled
    },
    lang: {
      type: Object // @see method label()
    },
    lookup: { // @see method label()
      type: String,
      default: 'label'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    help: {
      type: String,
      default: ''
    },
    changed: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      selectedIndex: -1,
      inputValue: null,
      isBlinking: false
    }
  },
  computed: {

    /**
     * selectedLabel is set, when the changed key is emmited and the new
     * value is set from outside.
     */
    selectedLabel () {
      if (this.options[this.selectedIndex]) {
        return this.label(this.options[this.selectedIndex])
      }
      return ''
    },
    hasError () {
      return this.error
    },
    hasHelp () {
      return fn.isString(this.help)
    }
  },
  methods: {

    /**
     * Ways to get the label from:
     * 1. from lang-object: key: { lookup: value }
     * 2. from lang-object: key: value
     * 3. from options-object: [ key, lookup ]
     * 4. key
     */ 
    label (option) {
      if (fn.has(this.lang, option.key)) {
        return fn.isObject(this.lang[option.key]) ? this.lang[option.key][this.lookup] : this.lang[option.key]
      } else if (fn.has(option, this.lookup)) {
        return option[this.lookup]
      }
      return option.key
    },
    blink () {
      this.isBlinking = true
      window.setTimeout(() => {
        this.isBlinking = false
      }, 300)
    },
    setSelectedIndex () {
      fn.each(this.options, (option, index) => {
        if (option.key === this.value) {
          this.selectedIndex = index
        }
      })
    },
    changeHandler (Event) {
      if (!this.disabled) {
        this.publish(this.inputValue)
      }
    },
    publish (value) {
      if (fn.isObject(this.callback)) {
        var params = fn.assign({
          value: value,
          event: 'change'
          }, this.callback
        )
        if(fn.isString(this.callback.action)) {
          this.$store.dispatch(this.callback.action, params)
        } else if(fn.isString(this.callback.mutation)) {
          this.$store.commit(this.callback.mutation, params)
        } else {
          info('wrong parameter for callback in select control')
        }
      } else {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(key) {
        this.inputValue = key
        this.setSelectedIndex()
      }
    },
    changed: {
      handler(count) {
        this.blink()
      }
    }
  }
}
</script>

<style lang="sass">
.select-control
</style>