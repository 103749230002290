import Vue from 'vue'
import API from '../services/API'

/*
| Project data, in combinition with Configurator
|
|--------------------------------------------------------------------------
| Conditions
|--------------------------------------------------------------------------
|
| #1 inclination 35 not available when norm = ASME
| #2 speed 0.65 not available when norm = EN115 and inclination = 35
| #3 2 flatsteps not available when speed = 0.65
| #4 rise max 6m when inclination is 35
| #5 norm depends on country, @see lookup-table for country
| #6 total 16m rise over two floors when product = velino | tugela | victoria
| #7 powerSupply 278_480 and 347_600 not available when frequency = 50
| #8 ballustrade slim not available when climate class = outdoor
| #9 unitsDistance not available when unitsCount = 1
| #10 arrangement parallel and crisscross not available when unitsCount = 1
| #11 arrangement opencrisscross not available when floorsCount = 1
| #12 arrangement single not available when floorsCount = 2
| #13 orinocohorizontal and iwalk: unitsCount = 1 > arrangement = single, unitsCount = 2 > arrangement = parallel
*/
const projectStore = {
  namespaced: true,

  /*
  |--------------------------------------------------------------------------
  | State
  |--------------------------------------------------------------------------
  */
  state: {

    // project data
    // {
    //    type: [ select, integer ],
    //    value: String | Number,
    //    default: nString | Number,
    //    error: Boolean,
    //    options: {
    //      key: String | Number,
    //      disabled: Boolean
    //    },
    //    min: Number,
    //    max: Number
    //  }
    fields: {
      product: null,
      country: null,
      norm: null,
      unit: null,
      floorsCount: null,
      pit: null,
      unitsCount: null,
      unitsDistance: null,
      arrangement: null,
      ballustrade: null,
      supportType: null,
      powerSupply: null,
      frequency: null,
      climateClass: null,
      floors: [{
        rise: null,
        length: null,
        inclination: null,
        stepWidth: null,
        speed: null,
        flatSteps: null,
        headLength: null
      }]
    },

    // the config
    config: {
      current: {},
      settings: {},
      steps: [
        ['country', 'norm', 'unit'],
        ['floorsCount', 'pit', 'unitsCount', 'unitsDistance', 'arrangement'],
        ['rise', 'length', 'inclination', 'stepWidth', 'speed', 'flatSteps', 'headLength'],
        ['ballustrade', 'supportType', 'powerSupply', 'frequency', 'climateClass']
      ]
    },

    // each request has unique id
    requestId: null,

    // each floor = single request
    requestCount: 0,

    // array with each floor as result object
    result: [],

    // global error flag up to current step
    error: false,

     /**
     * Init status
     */
    init: false,
    
    /**
    */
    currentStep: 1,

    /**
     */
    stepCount: 5 // last is result step
  },

  /*
  |--------------------------------------------------------------------------
  | Getters
  |--------------------------------------------------------------------------
  */

  getters: {

    /**
     */
    isMetric: (state) => {
      return state.fields.unit.value === 'metric'
    },

    /**
     */
    isImperial: (state) => {
      return state.fields.unit.value === 'imperial'
    },

    /**
     */
    product: (state) => {
      return fn.isObject(state.fields.product) ? state.fields.product.value : null
    },

    /**
     */
    hasField: (state) => (key) => {
      return fn.has(state.config.current, key) &&
        fn.isObject(state.config.current[key]) &&
        state.config.current[key].type !== 'hidden'
    },

    /**
     */
    hasSecondFloor: (state) => {
      return state.fields.floors.length === 2
    },

    /**
     */
    hasSecondUnit: (state) => {
      return state.fields.unitsCount.value === 2
    },

    /**
     */
    hasRise: (state, getters) => {
      return fn.isObject(state.fields.floors[0].rise) && !getters.hasLength // to be sure
    },

    /**
     */
    hasLength: (state, getters) => {
      return fn.isObject(state.fields.floors[0].length) && !getters.hasRise // to be sure
    },

    /**
     */
    riseMax35: (state, getters) => {
      var numConfig = getters.numConfig('rise')
      if (fn.has(numConfig, 'max_35')) {
        return numConfig.max_35
      }
      return null
    },

    /**
     */
    riseMaxTotal: (state, getters) => {
      var numConfig = getters.numConfig('rise')
      if (fn.has(numConfig, 'max_total')) {
        return numConfig.max_total
      }
      return null
    },

    /**
     * Calculates capacity on the base of speed, stepWidth and unitsCount
     * formular is independent from product
     * @return {Array}
     */
    capacity: (state, getters) => {
      var speed, stepWidth, res = []
      var conf = state.config.settings.capacity
      var unitsCount = state.fields.unitsCount.value
      fn.each(state.fields.floors, (floor, key) => {
        speed = floor.speed.value
        stepWidth = floor.stepWidth.value
        res.push(conf.speed[speed] * conf.stepWidth[stepWidth] * unitsCount)
      })
      return res
    },

    /**
     */
    hasError: (state) => {
      return state.error
    },

    /**
     */
    error: (state) => (params) => {
      if (fn.isInteger(params.floor, 0)) {
        return fn.has(state.fields.floors, params.floor) &&
          fn.isObject(state.fields.floors[params.floor][params.name]) &&
          fn.isTrue(state.fields.floors[params.floor][params.name].error)
      } else {
        return fn.isObject(state.fields[params.name])
          && fn.isTrue(state.fields[params.name].error)
      }
    },

    /**
     * @return {Integer}
     */
    currentStep: (state) => {
      return state.currentStep
    },

    /**
     * @return {Integer}
     */
    stepCount: (state) => {
      return state.stepCount
    },

    /**
     * @return {String}
     */
    currentStepSlug: (state, getters) => {
      if (state.currentStep === state.stepCount) {
        return 'result'
      } else {
        return 'step-' + getters.currentStep
      }
    },

    /**
     * @param {Object} state
     * @return {Integer}
     */
    totalStepsCount: (state) => {
      return state.stepCount - 1
    },

    /**
     * get the config of number fields depending on imperial/metric
     */
    numConfig: (state, getters) => (key) => {
      if (getters.hasField(key) && state.config.current[key].type === 'number') {
        return getters.isImperial ? state.config.current[key].imperial : state.config.current[key].metric
      }
      return null
    },

    /**
     */
    riseTotal: (state, getters) => {
      var res = 0
      if (getters.hasField('rise')) {
        fn.each(state.fields.floors, (floor) => {
          res += floor.rise.value
        })
      }
      return res
    },

    /**
     */
    valuesForApiCalculate: (state, getters) => (floorKey) => {
      var res = {
        floor: floorKey
      }
      fn.each(state.fields, (props, name) => {
        if (!fn.isObject(props) || name === 'floors' || name === 'unit') {
          return
        }
        if (name === 'pit' && floorKey > 0) {
          props.value = false
        }
        res[name] = getters.valueForApiCalculate(props)
      })
      fn.each(state.fields.floors[floorKey], (props, name) => {
        if (!fn.isObject(props)) {
          return
        }
        res[name] = getters.valueForApiCalculate(props)
      })
      return res
    },

    /**
     * convert values for API
     */
    valueForApiCalculate: (state) => (props) => {
      switch(props.type) {
        case 'select':
          return props.value
        case 'number':

          // return mm from every value
          if (props.unit === 'imperial') {
            return fn.toMetric(0, props.value)
          } else {
            return props.value * props.factor
          }
        case 'bool':
          return fn.isTrue(props.value) ? 1 : 0
        case 'hidden':
          return props.value
      }
    },

    /**
     */

    /**
     */
    valuesForApiSketch: (state, getters, rootState) => {
      var res = []
      fn.each(state.result, (floor, key) => {
        res[key] = fn.clone(floor)
        res[key].language = rootState.lang
      })
      return res
    },
  },

  /*
  |--------------------------------------------------------------------------
  | Mutations
  |--------------------------------------------------------------------------
  */

  mutations: {

    /**
     * {
     *   name: fieldname,
     *   value: value to set
     *   options: list with opitions to enable/disable
     *   floor: optional floor-key
     *   default: value
     *   min: number
     *   max: number
     *   disabled: Boolean
     *   changed: Boolean
     * }
     * @param {Object} state
     * @param {Object} params
     */
    updateField (state, params) {
      var ref, valid

      // set reference to field, either member of a given floor or not
      if (fn.has(params, 'floor')) {
        if (fn.isInteger(params.floor, 0) && fn.has(state.fields.floors, params.floor) && fn.isObject(state.fields.floors[params.floor][params.name])) {
          ref = state.fields.floors[params.floor][params.name]
        } else {
          return
        }
      } else if (fn.isObject(state.fields[params.name])) {
        ref = state.fields[params.name]
      } else {
        return
      }
      
      // update min
      if (fn.has(params, 'min')) {
        ref.min = params.min
      }

      // update max
      if (fn.has(params, 'max')) {
        ref.max = params.max
      }

      // update decimals
      if (fn.has(params, 'decimals')) {
        ref.decimals = params.decimals
      }

      // update value
      if (fn.has(params, 'value')) {

        // validate
        valid = false
        switch(ref.type) {
          case 'select':
            fn.each(ref.options, (option) => {
              if (option.key === params.value) {
                valid = true
              }
            })
            break
          case 'number':
            valid = fn.isNumber(params.value, ref.min, ref.max)
            break
          case 'bool':
            valid = fn.isBool(params.value)
            break;
          case 'hidden':
            valid = true
        }

        // set value
        // also set wrong values, otherwise validation could not take place
        ref.value = params.value
        ref.error = !valid
      }

      // options
      if (fn.has(params, 'options')) {
        var change = false
        var defaultVal = null
        var firstVal = null
        var map = {}
        fn.each(params.options, (option) => {
          map[option.key] = option
        })
        fn.each(ref.options, (option) => {
          if (fn.has(map, option.key)) {
            option.disabled = map[option.key].disabled
          } else {
            option.disabled = false
          }
          if (option.key === ref.default && option.disabled === false) {
            defaultVal = option.key
          }
          if (firstVal === null && option.disabled === false) {
            firstVal = option.key
          }
          if (option.disabled === true && option.key === ref.value) {
            change = true
          }
        })
        if (change) {
          if (defaultVal) {
            ref.value = defaultVal
          } else if (firstVal) {
            ref.value = firstVal
          } else {
            ref.value = null
            info('Error in field ' + ref.name + ': no valid option available')
          }
        } else {
          params.changed = false
        }
      }

      // update default
      if (fn.has(params, 'default')) {
        ref.default = params.default
      }

      // update disabled
      if (fn.has(params, 'disabled')) {
        ref.disabled = params.disabled
      }

      // update confirmed
      if (
        ref.type === 'number' &&
        (
          (fn.has(params, 'event') && params.event === 'change') ||
          (fn.has(params, 'confirm') && params.confirm)
        )
      ) {
          ref.confirmed = ref.value
      }

      // update changed
      if (fn.has(params, 'changed') && params.changed) {
        ref.changed++
      }

      // update  unit
      if (fn.has(params, 'unit')) {
        ref.unit = params.unit
      }
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Actions
  |--------------------------------------------------------------------------
  */

  actions: {

    /*
    |--------------------------------------------------------------------------
    | Init
    |--------------------------------------------------------------------------
    */

    /**
     * Init store (load config files)
     * @return {Promise}
     */
    init ({ state, dispatch, rootGetters }, product) {
      
      // check product
      if ( ! rootGetters['products/valid'](product)) {
        product = rootGetters['products/default']
      }
      info ('init configurator with product ' + product)

      // request config from TKE API
      return API.project(product)
        .then((response) => {
          state.config.current = response.data.fields
          state.config.settings = response.data.settings

          // metric or imperial (relevant for numbers)
          var unit = state.init ? state.fields.unit.value : state.config.current.unit.default

          // loop fields and set state
          var options
          var numConfig
          fn.each(state.config.current, (config, name) => {

            if (state.init && (name === 'country' || name === 'norm' || name === 'unit')) {
              return
            }

            // init field and create reference
            var field
            if (fn.has(state.fields.floors[0], name)) {
              state.fields.floors[0][name] = fn.isObject(config) ? {} : false
              field = state.fields.floors[0][name]
            } else if (fn.has(state.fields, name)) {
              state.fields[name] = fn.isObject(config) ? {} : false
              field = state.fields[name]
            }

            // field can be false (not used) or object
            // init field on the basis of type
            if (fn.isObject(field)) {
              Vue.set(field, 'type', config.type)
              Vue.set(field, 'error', false)
              Vue.set(field, 'disabled', false)
              switch(config.type) {
                case 'select':
                case 'hidden':
                  Vue.set(field, 'value', config.default)
                  Vue.set(field, 'default', config.default)
                  Vue.set(field, 'changed', 0)
                  if (fn.has(config, 'values')) { // hidden fields don't have always
                    options = []
                    var keys = []
                    if (fn.isArray(config.values)) {
                      keys = config.values
                    } else if (fn.isObject(config.values)) {
                      keys = Object.keys(config.values)
                    }
                    fn.each(keys, (key) => {
                      options.push({
                        key: key,
                        disabled: false
                      })
                    })
                    Vue.set(field, 'options', options)
                  }
                  break
                case 'number':
                  numConfig = unit === 'imperial' ? config.imperial : config.metric
                  Vue.set(field, 'value', numConfig.default)
                  Vue.set(field, 'default', numConfig.default)
                  Vue.set(field, 'min', fn.isNumber(numConfig.min) ? numConfig.min : null)
                  Vue.set(field, 'max', fn.isNumber(numConfig.max) ? numConfig.max : null)
                  Vue.set(field, 'decimals', fn.isNumber(numConfig.decimals) ? numConfig.decimals : 0)
                  Vue.set(field, 'confirmed', numConfig.default) // the value when the field is blurred an corrected
                  Vue.set(field, 'changed', 0)
                  Vue.set(field, 'unit', unit)
                  Vue.set(field, 'factor', config.factor)
                  break
                case 'bool':
                  Vue.set(field, 'value', config.default)
                  Vue.set(field, 'default', config.default)
                  Vue.set(field, 'changed', 0)
                  break
              }
            }
          })

          // init fields
          dispatch('setProduct', { value: product })
          if (!state.init) {
            dispatch('updateNorm')
            dispatch('updateUnit')
          }
          dispatch('updateFloors')
          dispatch('updateUnitsDistance')
          dispatch('updateArrangement')
          dispatch('updateRiseMax')
          dispatch('updateInclination')
          dispatch('updateSpeed')
          dispatch('updateFlatSteps')
          dispatch('updateBallustrade')
          dispatch('updatePowerSupply')

          // init step
          state.currentStep = 1
          state.init = true
          state.error = false
        })
    },

    /*
    |--------------------------------------------------------------------------
    | Configurator
    |--------------------------------------------------------------------------
    */

    /**
     * Set the next step
     * @param {Integer} step, optional
     */
    nextStep ({ state, dispatch }, step) {
      if (state.currentStep >= state.stepCount) {
        return Promise.reject('not allowed')
      }
      dispatch('setStepError')
        .then(() => {
          if (fn.isInteger(step, 1, state.stepCount)) {
            state.currentStep = step
          } else {
            state.currentStep++
          }
          dispatch('setStepError').catch(() => {})
          return Promise.resolve()
        })
        .catch(() => {})
    },

    /**
     * Remove last step from state.steps
     */
    prevStep ({ state, dispatch }) {
      if (state.currentStep <= 1) {
        return Promise.reject('not allowed')
      }
      state.currentStep--
      dispatch('setStepError').catch(() => {})
      return Promise.resolve()
    },

    /**
     * Send project data to API
     * send a request for each floor
     * @TODO: check, if floors are identical, then send only one request
     */
    calculateProject ({ state, getters }) {
      var post, requests = []
      state.requestId = fn.uuidv4()
      state.requestCount = state.fields.floors.length
      state.result = []
      fn.each(state.fields.floors, (floor, key) => {
        post = getters.valuesForApiCalculate(key)
        post.requestId = state.requestId
        post.floor = key
        requests.push(API.calculate(post))
      })
      return Promise.all(requests)
        .then((responses) => {
          fn.each(responses, (response) => {
            var res = response.data
            var floor = fn.toInteger(res.floor)
            if (state.requestId !== res.requestId) {
              return
            }
            state.result[floor] = {}
            fn.each(res, (value, name) => {
              state.result[floor][name] = value
            })
          })
          if (state.requestCount !== state.result.length) {
            state.result = []
            return Promise.reject({ status: 500 })
          }
        })
        .catch((reject) => {
          state.result = []
          return Promise.reject(reject)
        })
    },

    /**
     * Send result to API to configure CAD drawings
     */
    configureSketch ({ state, getters }) {
      if (!fn.isArray(state.result) || state.result.length === 0) {
        return Promise.reject()
      }
      return API.sketch(getters.valuesForApiSketch)
    },

    /*
    |--------------------------------------------------------------------------
    | Errors
    |--------------------------------------------------------------------------
    */

    /**
     * set global error flag depending on current step
     */
    setStepError ({ state, getters }) {
      var error = false

      // single field errors
      fn.each(state.config.steps, (names, key) => {
        if (key >= state.currentStep) {
          return
        }
        if (key === 2) {
          fn.each(state.fields.floors, (floor, floorKey) => {
            fn.each(names, (name) => {
              if (getters.error({ name: name, floor: floorKey })) {
                error = true
              }
            })
          })
          return
        }
        fn.each(names, (name) => {
          if (getters.error({ name: name })) {
            error = true
          }
        })
      })

      // multiple fields error
      if (state.currentStep === 3 && getters.riseMaxTotal) {
        if (getters.riseTotal > getters.riseMaxTotal) {
          error = true
        }
      }
      state.error = error
      if (error) {
        return Promise.reject()
      }
      return Promise.resolve()
    },

    /*
    |--------------------------------------------------------------------------
    | Product
    |--------------------------------------------------------------------------
    */

    /**
     */
    setProduct ({ commit, dispatch }, params) {
      params.name = 'product'
      commit('updateField', params)
      dispatch('setStepError').catch(() => {})
    },

    /*
    |--------------------------------------------------------------------------
    | Country
    |--------------------------------------------------------------------------
    */

    /**
     */
    setCountry ({ commit, dispatch }, params) {
      params.name = 'country'
      commit('updateField', params)
      dispatch('setStepError').catch(() => {})
      dispatch('updateNorm')
      dispatch('updateUnit')
    },

    /*
    |--------------------------------------------------------------------------
    | Norm
    |--------------------------------------------------------------------------
    */

    /**
     */
    setNorm ({ commit, dispatch }, params) {
      params.name = 'norm'
      commit('updateField', params)
      dispatch('updateInclination')
      dispatch('updateSpeed')
      dispatch('setStepError').catch(() => {})
    },

    /**
     * condition #5
     * invoked by setCountry
     */
    updateNorm ({ state, dispatch }) {
      //log('update norm (condition #5)')
      var country = state.fields.country.value
      var defKey = state.config.current.country.values[country][0]
      var availKeys = state.config.current.country.values[country][1]
      state.fields.norm.default = state.fields.norm.options[defKey].key
      fn.each(state.fields.norm.options, (option, key) => {
        option.disabled = !fn.inArray(key, availKeys)
      })
      dispatch('setNorm', {
        value: state.fields.norm.default
      })
    },

    /*
    |--------------------------------------------------------------------------
    | Unit
    |--------------------------------------------------------------------------
    */

    /**
     */
    setUnit ({ state, commit, dispatch }, params) {
      params.name = 'unit'
      var changed = params.value !== state.fields.unit.value
      commit('updateField', params)
      if (changed) {
        dispatch('updateUnitsDistanceUnit')
        fn.each(state.fields.floors, (floor, key) => {
          dispatch('updateRiseUnit', key)
          dispatch('updateLengthUnit', key)
        })
      }
      dispatch('setStepError').catch(() => {})
    },

    /**
     * Set unit to setting in countries
     */
    updateUnit ({ state, commit, dispatch }) {
      var country = state.fields.country.value
      var defKey = state.config.current.country.values[country][2]
      dispatch('setUnit', {
        value: state.fields.unit.options[defKey].key,
        default: state.fields.unit.options[defKey].key
      })
    },

    /*
    |--------------------------------------------------------------------------
    | floorsCount
    |--------------------------------------------------------------------------
    */

    /**
     */
    setFloorsCount ({ commit, dispatch }, params) {
      params.name = 'floorsCount'
      commit('updateField', params)
      dispatch('updateArrangement')
      dispatch('updateFloors')
      dispatch('updateRiseMax')
      dispatch('setStepError').catch(() => {})
    },

    /**
     * Set number of floors as defined in floorsCount
     */
    updateFloors ({ state }) {
      var count = state.fields.floorsCount.value
      var length = state.fields.floors.length
      var last = length - 1
      if (fn.isInteger(count, 1)) {
        if (count > length) {
          for (var i = length; i < count; i++) {

            // simple way for deep cloning, only possible, because we only have 
            // simple value types in the object
            state.fields.floors.push(JSON.parse(JSON.stringify(state.fields.floors[last])))
          }
        } else if (count < length) {
          state.fields.floors = state.fields.floors.slice(0, count)
        }
      }
    },

    /*
    |--------------------------------------------------------------------------
    | Pit
    |--------------------------------------------------------------------------
    */

    /**
     */
    setPit ({ commit, dispatch }, params) {
      params.name = 'pit'
      commit('updateField', params)
      dispatch('setStepError').catch(() => {})
    },

    /*
    |--------------------------------------------------------------------------
    | unitsCount
    |--------------------------------------------------------------------------
    */

    /**
     */
    setUnitsCount ({ commit, dispatch }, params) {
      params.name = 'unitsCount'
      commit('updateField', params)
      dispatch('updateUnitsDistance')
      dispatch('updateArrangement')
      dispatch('updateRiseMax')
      dispatch('setStepError').catch(() => {})
    },

    /*
    |--------------------------------------------------------------------------
    | unitsDistance
    |--------------------------------------------------------------------------
    */

    /**
     */
    setUnitsDistance ({ commit, dispatch }, params) {
      params.name = 'unitsDistance'
      commit('updateField', params)
      dispatch('setStepError').catch(() => {})
    },

    /**
     * change rise after change of unit
     */
    updateUnitsDistanceUnit ({ dispatch, getters }) {
      if (!getters.hasField('unitsDistance')) {
        return
      }
      var unit = getters.isImperial ? 'imperial' : 'metric'
      var numConfig = getters.numConfig('unitsDistance')
      var params = {
        value: numConfig.default,
        default: numConfig.default,
        min: fn.isNumber(numConfig.min) ? numConfig.min : null,
        max: fn.isNumber(numConfig.max) ? numConfig.max : null,
        decimals: fn.isNumber(numConfig.decimals) ? numConfig.decimals : 0,
        unit: unit,
        confirm: true
      }
      dispatch('setUnitsDistance', params)
    },

    /**
     * condition #9
     * invoked by setUnitsCount
     */
    updateUnitsDistance ({ state, dispatch }) {
      //log('update unitsDistance (condition #9)')
      var disabled = (state.fields.unitsCount.value === 1)
      dispatch('setUnitsDistance', {
        disabled: disabled
      })
    },

    /*
    |--------------------------------------------------------------------------
    | arrangement
    |--------------------------------------------------------------------------
    */

    /**
     */
    setArrangement ({ commit, dispatch }, params) {
      params.name = 'arrangement'
      commit('updateField', params)
      dispatch('setStepError').catch(() => {})
    },

    /**
     * condition #10, #11, #12, #13
     * invoked by setFloorsCount, setUnitsCount
     */
    updateArrangement ({ state, dispatch, rootGetters }) {
      //log('update arrangement (condition #10, #11, #12, #13)')
      var hasOneUnit = state.fields.unitsCount.value === 1
      var hasTwoUnits = state.fields.unitsCount.value === 2
      var hasOneFloor = state.fields.floorsCount.value === 1
      var isHorizontal = rootGetters['products/inclination'] === 'horizontal'
      dispatch('setArrangement', {
        options: [
          { key: 'single', disabled: hasTwoUnits },
          { key: 'parallel', disabled: hasOneUnit },
          { key: 'crisscross', disabled: hasOneUnit || isHorizontal },
          { key: 'opencrisscross', disabled: hasOneFloor || isHorizontal },
        ],
        changed: true
      })
    },

    /*
    |--------------------------------------------------------------------------
    | ballustrade
    |--------------------------------------------------------------------------
    */

    /**
     */
    setBallustrade ({ commit, dispatch }, params) {
      params.name = 'ballustrade'
      commit('updateField', params)
      dispatch('setStepError').catch(() => {})
    },

    /**
     * condition #8
     * invoked by setClimateClass
     */
    updateBallustrade ({ state, dispatch }) {
      //log('update ballustrade (condition #8)')
      var disabled = state.fields.climateClass.value === 'outdoor'
      dispatch('setBallustrade', {
        name: 'ballustrade',
        options: [
          { key: 'slim', disabled: disabled }
        ],
        changed: true
      })
    },

    /*
    |--------------------------------------------------------------------------
    | supportType
    |--------------------------------------------------------------------------
    */

    /**
     */
    setSupportType ({ commit, dispatch }, params) {
      params.name = 'supportType'
      commit('updateField', params)
      dispatch('setStepError').catch(() => {})
    },

    /*
    |--------------------------------------------------------------------------
    | powerSupply
    |--------------------------------------------------------------------------
    */

    /**
     */
    setPowerSupply ({ commit, dispatch }, params) {
      params.name = 'powerSupply'
      commit('updateField', params)
      dispatch('setStepError').catch(() => {})
    },

    /**
     * condition #7
     * invoked by setFrequency
     */
    updatePowerSupply ({ state, dispatch }) {
      //log('update powerSupply (condition #7)')
      var disabled = (state.fields.frequency.value === 50)
      dispatch('setPowerSupply', {
        options: [
          { key: '278_480', disabled: disabled },
          { key: '347_600', disabled: disabled }
        ],
        changed: true
      })
    },

    /*
    |--------------------------------------------------------------------------
    | frequency
    |--------------------------------------------------------------------------
    */

    /**
     */
    setFrequency ({ commit, dispatch }, params) {
      params.name = 'frequency'
      commit('updateField', params)
      dispatch('updatePowerSupply')
      dispatch('setStepError').catch(() => {})
    },

    /*
    |--------------------------------------------------------------------------
    | climateClass
    |--------------------------------------------------------------------------
    */

    /**
     */
    setClimateClass ({ commit, dispatch }, params) {
      params.name = 'climateClass'
      commit('updateField', params)
      dispatch('updateBallustrade')
      dispatch('setStepError').catch(() => {})
    },

    /*
    |--------------------------------------------------------------------------
    | rise
    |--------------------------------------------------------------------------
    */

    /**
     */
    setRise ({ commit, dispatch }, params) {
      params.name = 'rise'
      commit('updateField', params)
      if (params.event === 'change') {
        dispatch('updateRise', params.floor)
      }
      dispatch('setStepError').catch(() => {})
    },

    /**
     * condition #6
     * invoked by setRise
     */
    updateRise ({ state, dispatch, getters }, floorKey) {
      if (!getters.hasField('rise')) {
        return
      }
      // condition #4
      // change value on same floor, when max reduces by changing inclination to 35
      if (state.fields.floors[floorKey].rise.max < state.fields.floors[floorKey].rise.value) {
        dispatch('setRise', {
          value: state.fields.floors[floorKey].rise.max,
          floor: floorKey,
          event: 'change',
          changed: true
        })
      }

      // condition #6
      // change value when max total is reached
      if(getters.hasSecondFloor && getters.riseMaxTotal) {
        if (getters.riseTotal > getters.riseMaxTotal) {
          var otherKey = floorKey === 0 ? 1 : 0
          var newVal = getters.riseMaxTotal - state.fields.floors[otherKey].rise.value
          if (newVal < state.fields.floors[floorKey].rise.min) {
            newVal = state.fields.floors[floorKey].rise.min
          }
          dispatch('setRise', {
            value: newVal,
            floor: floorKey,
            event: 'change',
            changed: true
          })
        }
      }
    },

    /**
     * change rise after change of unit
     */
    updateRiseUnit ({ dispatch, getters }, floorKey) {
      if (!getters.hasField('rise')) {
        return
      }
      var unit = getters.isImperial ? 'imperial' : 'metric'
      var numConfig = getters.numConfig('rise')
      var params = {
        value: numConfig.default,
        default: numConfig.default,
        min: fn.isNumber(numConfig.min) ? numConfig.min : null,
        max: fn.isNumber(numConfig.max) ? numConfig.max : null,
        decimals: fn.isNumber(numConfig.decimals) ? numConfig.decimals : 0,
        unit: unit,
        floor: floorKey,
        confirm: true
        //event: 'change' don't, endless loop! default values are expected as valid so updateRise() is not necessary
      }
      dispatch('setRise', params)
    },

    /**
     * condition #4, #6
     * invoked by setFloorsCount
     */
    updateRiseMax ({ state, dispatch, getters }) {
      if (!getters.hasField('rise')) {
        return
      }
      //log('update rise max (condition #6, #4)')
      var params
      var numConfig = getters.numConfig('rise')
      fn.each(state.fields.floors, (floor, key) => {
        params = {
          floor: key,
          max: numConfig.max
        }
        if (getters.riseMax35 && floor.inclination.value === 35) {
          params.max = getters.riseMax35
        }
        if (getters.riseMaxTotal && state.fields.floors.length > 1) {
          if (getters.riseMaxTotal - numConfig.min < params.max) {
            params.max = getters.riseMaxTotal - (numConfig.min * (state.fields.floors.length - 1))
          }
        }
        dispatch('setRise', params)
        dispatch('updateRise', key)
      })
    },

    /*
    |--------------------------------------------------------------------------
    | length
    |--------------------------------------------------------------------------
    */

    /**
     */
    setLength ({ commit, dispatch }, params) {
      params.name = 'length'
      commit('updateField', params)
      dispatch('setStepError').catch(() => {})
    },

    /**
     * change length after change of unit
     */
    updateLengthUnit ({ dispatch, getters }, floorKey) {
      if (!getters.hasField('length')) {
        return
      }
      var unit = getters.isImperial ? 'imperial' : 'metric'
      var numConfig = getters.numConfig('length')
      var params = {
        value: numConfig.default,
        default: numConfig.default,
        min: fn.isNumber(numConfig.min) ? numConfig.min : null,
        max: fn.isNumber(numConfig.max) ? numConfig.max : null,
        decimals: fn.isNumber(numConfig.decimals) ? numConfig.decimals : 0,
        unit: unit,
        floor: floorKey,
        confirm: true
      }
      dispatch('setLength', params)
    },

    /*
    |--------------------------------------------------------------------------
    | inclination
    |--------------------------------------------------------------------------
    */

    /**
     */
    setInclination ({ commit, dispatch }, params) {
      params.name = 'inclination'
      commit('updateField', params)
      dispatch('updateRiseMax')
      dispatch('updateSpeed', params.floor)
      dispatch('setStepError').catch(() => {})
    },

    /**
     * condition #1
     * invoked by setNorm
     */
    updateInclination ({ state, dispatch }) {
      //log('update inclination (condition #1)')
      var params = {
        options: [
          { key: 35, disabled: state.fields.norm.value === 'asme' }
        ]
      }
      fn.each(state.fields.floors, (floor, key) => {
        params.floor = key
        dispatch('setInclination', params)
      })
    },

    /*
    |--------------------------------------------------------------------------
    | stepWidth
    |--------------------------------------------------------------------------
    */

    /**
     */
    setStepWidth ({ commit, dispatch }, params) {
      params.name = 'stepWidth'
      commit('updateField', params)
      dispatch('setStepError').catch(() => {})
    },

    /*
    |--------------------------------------------------------------------------
    | speed
    |--------------------------------------------------------------------------
    */

    /**
     */
    setSpeed ({ commit, dispatch }, params) {
      params.name = 'speed'
      commit('updateField', params)
      dispatch('updateFlatSteps', params.floor)
      dispatch('setStepError').catch(() => {})
    },

    /**
     * condition #2
     * invoked by setNorm, setInclination
     */
    updateSpeed ({ state, dispatch}, floorKey) {
      //log('update speed (condition #2)')
      var disabled
      fn.each(state.fields.floors, (floor, key) => {
        if (fn.isInteger(floorKey, 0) && floorKey !== key) {
          return
        }
        disabled = state.fields.norm.value === 'en115' && floor.inclination.value === 35
        dispatch('setSpeed', {
          floor: key,
          options: [
            { key: 0.65, disabled: disabled}
          ]
        })
      })
    },

    /*
    |--------------------------------------------------------------------------
    | flatSteps
    |--------------------------------------------------------------------------
    */

    /**
     */
    setFlatSteps ({ commit, dispatch }, params) {
      params.name = 'flatSteps'
      commit('updateField', params)
      dispatch('setStepError').catch(() => {})
    },

    /**
     * condition #3
     * invoked by setSpeed
     */
    updateFlatSteps ({ state, dispatch }, floorKey) {
      //log('update flatSteps (condition #3)')
      var disabled
      fn.each(state.fields.floors, (floor, key) => {
        if (fn.isInteger(floorKey, 0) && floorKey !== key) {
          return
        }
        disabled = floor.speed.value === 0.65
        dispatch('setFlatSteps', {
          floor: key,
          options: [
            { key: 2, disabled: disabled}
          ],
          changed: true
        })
      })
    },

    /*
    |--------------------------------------------------------------------------
    | headLength
    |--------------------------------------------------------------------------
    */

    /**
     */
    setHeadLength ({ commit, dispatch }, params) {
      params.name = 'headLength'
      commit('updateField', params)
      dispatch('setStepError').catch(() => {})
    }
  }
}

export default projectStore