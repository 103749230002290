<template>
  <component
    :is="component"
    class="row">
      <slot></slot>
  </component>
</template>

<script>

export default {
  name: 'row-elem',
  props: {
    'component': {
      type: String,
      default: 'div'
    }
  }
}
</script>

<style lang="sass">
.elem
</style>
