import Vue from 'vue'
import Router from 'vue-router'
import Store from '@/services/Store'
import API from '@/services/API'
import DOM from '@/services/DOM'
import Scroll from '@/services/Scroll'
import DefaultTemplate from '@/components/templates/Default.vue'

const Default = () => import(/* webpackChunkName: `default-page` */ '@/components/pages/Default')
const Home = () => import(/* webpackChunkName: `home-page` */ '@/components/pages/Home')
const Error = () => import(/* webpackChunkName: `default-page` */ '@/components/pages/Error')
const Wizard = () => import(/* webpackChunkName: `wizard-page` */ '@/components/pages/Wizard')
const Configurator = () => import(/* webpackChunkName: `configurator-page` */ '@/components/pages/Configurator')

Vue.use(Router)

const routerService = class {
  constructor () {
    this.Instance = null
  }

  /*
  |--------------------------------------------------------------------------
  | Router and routes
  |--------------------------------------------------------------------------
  */

  /**
   * Create router
   * @return router
   */
  component () {
    this.Instance = new Router({
      mode: 'history',
      linkActiveClass: '',
      linkExactActiveClass: '',
      routes: [
        {
          path: '/',
          component: DefaultTemplate,
          redirect: this._getHomeUri(),
          children: [
            {
              // just to make incomplete routes with only lang work
              // (doesn't help for default langauges with no slug, which is correct!)
              path: this._getLangSlug(false),
              redirect: this._getHomeUri()
            },
            {
              path: this._getLangSlug(true) + '/home',
              name: 'home',
              component: Home
            },
            {
              path: this._getLangSlug(true) + '/wizard',
              name: 'wizard',
              redirect: (to) => {
                return fn.toUri(
                  to.params.lang,
                  'wizard/step-1'
                )
              }
            },
            {
              path: this._getLangSlug(true) + '/wizard/:step',
              component: Wizard,
              name: 'wizard-step',
              meta: {
                api: 'wizard'
              }
            },
            {
              path: this._getLangSlug(true) + '/configurator/:product?',
              name: 'configurator',
              redirect: (to) => {
                return fn.toUri(
                  to.params.lang,
                  'configurator',
                  to.params.product || Store.getters['products/default'],
                  'step-1'
                )
              }
            },
            {
              path: this._getLangSlug(true) + '/configurator/:product/:step',
              name: 'configurator-step',
              component: Configurator,
              meta: {
                api: 'configurator'
              }
            },
            {
              path: this._getLangSlug(true) + '/:slug(.+)',
              name: 'default',
              component: Default
            }
          ]
        }
      ]
    })

    this.Instance.beforeEach((to, from, next) => {
      if (Store.getters.siteError) {
        next()
        Scroll.smooth()
        return
      }

      // if meta.api is set, the path is taken from it
      var pathTo = fn.has(to.meta, 'api') && fn.isString(to.meta.api) ?
        fn.toUri(Store.getters.currentLanguage('slug'), to.meta.api) :
        to.fullPath

      var pathFrom = fn.has(from.meta, 'api') && fn.isString(from.meta.api) ?
        fn.toUri(Store.getters.currentLanguage('slug'), from.meta.api) :
        from.fullPath

      // do nothing when path didn't change
      // experimental
      if (pathTo === pathFrom && to.params.lang === from.params.lang) {
        next()
        Scroll.smooth()
        return 
      }

      // api request
      info('change api route ' + pathTo)
      info('route ' + to.name)
      API.node(pathTo)
        .then((response) => {
          response.pagekey = pathTo
          Store.commit('setPageData', response)
          DOM.setPageMeta()
          next()
          Scroll.smooth()
        })
        .catch((response) => {
          if (!fn.has(response, 'status')) {
            response.status = 1000
          }
          response.pagekey = pathTo
          Store.commit('setPageData', response)
          next()
          error(response.status, response.msg)
          Scroll.smooth()
        })
    })

    this.Instance.home = () => {
      return this._getHomeUri()
    }

    return this.Instance
  }

  setQuery (params, replaceQuery, addToHistory) {
    if (!replaceQuery) {
      params = fn.assign(this.Instance.currentRoute.query, params)
    }
    var query = []
    fn.each(params, (value, key) => {
      query.push(encodeURIComponent(key) + '=' + encodeURIComponent(value))
    })
    var path = this.Instance.currentRoute.path + '?' + query.join('&')
    if (addToHistory) {
      history.pushState({}, null, path)
    } else {
      history.replaceState({}, null, path)
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Helper
  |--------------------------------------------------------------------------
  */

  /**
   * 
   * @param {bool} optional, add optional quantifier (?), if a language
   * without a slug exists
   */
  _getLangSlug (optional) {
    let reg = []
    let quantifier = ''
    fn.each(Store.state.languages, (language) => {
      if (language.slug) {
        reg.push(language.slug)
      } else if (optional) {
        quantifier = '?'
      }
    })
    if (reg.length > 0) {
      return '/:lang(' + reg.join('|') + ')' + quantifier
    } else {
      return ''
    }
  }

  _getCurrentLangSlug () {
    return '/' + Store.getters.currentLanguage('slug')
  }

  _getHomeUri () {
    return fn.toUri(
      Store.getters.currentLanguage('slug'),
      Store.getters.currentLanguage('homeslug')
    )
  }
}

export default new routerService()
