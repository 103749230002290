import API from '@/services/API'

/**
 * Products methods
 */
const productsStore = {
  namespaced: true,

  /*
  |--------------------------------------------------------------------------
  | State
  |--------------------------------------------------------------------------
  */
 
  state: {
    default: null,
    products: []
  },

  /*
  |--------------------------------------------------------------------------
  | Getters
  |--------------------------------------------------------------------------
  */

  getters: {

    default: (state) => {
      return state.default
    },

    /**
     * Return Products in different formats
     * @param {Object} state
     * @param {String} format, [ 'object', 'array', 'options' ]
     * @param {String} type, [ 'escalators', 'movingwalks' ]
     * @return {mixed}
     */
    available: (state) => (format, type) => {
      var res = (format && format === 'object') ? {} : []
      fn.each(state.products, (config, product) => {
        if (type && type !== config.type) {
          return
        }
        switch (format) {
          case 'object':
            res[product] = true
            break
          case 'options':
            res.push({
              key: product,
              disabled: false
            })
            break
          default:
            res.push(product)
        }
      })
      return res
    },

    /**
     * get type of product
     * @param {String} key, Product-key
     * @return {String} (escalator or movingwalk)
     */
    type: (state) => (key) => {
      return state.products[key].type
    },

    /**
     * get inclination of product (not the same as type)
     * @param {String} key, Product-key
     * @return {String} (inclined or horizontal)
     */
    inclination: (state) => (key) => {
      return state.products[key].inclination
    },

    /**
     * check if product is valid
     * @param {String} key, Product-key
     * @return {Boolean}
     */
    valid: (state) => (key) => {
      if (!fn.isString(key)) {
        return false
      }
      return fn.has(state.products, key)
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Actions
  |--------------------------------------------------------------------------
  */

  actions: {

    /**
     * get products from config
     */
    init ({ state }) {
      return API.products()
        .then((response) => {
          state.products = response.data
          fn.each(state.products, (config, product) => {
            if (config.default) {
              state.default = product
            }
          })
        })
    }
  }
}



export default productsStore