<template>
  <div
    v-bind:class="[ pageClass, scrollClass, scrollDirectionClass ]"
    class="template default">
      <header-segment></header-segment>
      <product-segment v-if="$route.name === 'configurator-step'"></product-segment>
      <content-segment></content-segment>
      <footer-segment></footer-segment>
      <overlay-segment></overlay-segment>
      <dialog-segment></dialog-segment>
      <cookie-segment></cookie-segment>
      <debug-segment v-if="showDebugSegment"></debug-segment>
  </div>
</template>

<script>

// eslint-disable-next-line
import dom from '@/services/DOM'
import styles from '@/styles/main.sass'
import HeaderSegment from '@/components/segments/Header'
import ProductSegment from '@/components/segments/Product'
import ContentSegment from '@/components/segments/Content'
import FooterSegment from '@/components/segments/Footer'
import OverlaySegment from '@/components/segments/Overlay'
import DialogSegment from '@/components/segments/Dialog'
import CookieSegment from '@/components/segments/Cookie'
import DebugSegment from '@/debug/Debug'

export default {
  name: 'default-template',
  components: {
    HeaderSegment,
    ProductSegment,
    ContentSegment,
    FooterSegment,
    OverlaySegment,
    DialogSegment,
    DebugSegment,
    CookieSegment
  },
  data () {
    return {
      isScrolled: false,
      scrollDirection: false
    }
  },
  computed: {
    pageClass () {
      return 'page-'// + this.$route.name.replace('/', '-') // template!
    },
    scrollClass () {
      return this.isScrolled ? 'is-scrolled' : ''
    },
    scrollDirectionClass () {
      if(this.isScrolled) {
        return 'scroll-' + this.scrollDirection
      }
      return ''
    },
    showDebugSegment () {
      return fn.parseBool(process.env.VUE_APP_WINDOW_INFO)
    }
  },
  methods: {
    onWindowScroll (Event, params) {
      this.scrollDirection = params.direction
      this.isScrolled = params.scrolled
    }
  },
  events: {
    'window/scroll': 'onWindowScroll'
  }
}
</script>

<style lang="sass">
.template
</style>
