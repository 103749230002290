const RESULT_STEP = '__RESULT__'

/**
 * Wizard step control and business logic
 */
const wizardStore = {
  namespaced: true,

  /*
  |--------------------------------------------------------------------------
  | State
  |--------------------------------------------------------------------------
  */
  state: {

    /**
     * Init status
     */
    init: false,

    /**
     * Step history, last entry is current step
     */
    steps: [ 1 ],

    /**
     * Remaining steps from the current step on
     */
    remaining: null,
    
    /**
    * Data collection of all steps
    * -1 = unset, so it's possible to have null value as a valid value
    */
    data: {
      type: {
        value: -1,
        options: [
          { key: 'escalator', disabled: false },
          { key: 'movingwalk', disabled: false }
        ]
      },
      segment: {
        value: -1,
        options: [
          { key: 'airport', disabled: false },
          { key: 'entertainment', disabled: false },
          { key: 'hospital', disabled: false },
          { key: 'hotel', disabled: false },
          { key: 'office', disabled: false },
          { key: 'railway', disabled: false },
          { key: 'retail', disabled: false }
        ]
      },
      traffic: {
        value: -1,
        options: [
          { key: 'high', disabled: false },
          { key: 'peak', disabled: false },
          { key: 'regular', disabled: false }
        ]
      },
      inclination: {
        value: -1,
        options: [
          { key: 'horizontal', disabled: false },
          { key: 'inclined', disabled: false }
        ]
      },
      distance: {
        value: -1,
        options: [
          { key: 'short', disabled: false },
          { key: 'long', disabled: false }
        ]
      },
      climate: {
        value: -1,
        options: [
          { key: 'indoor', disabled: false },
          { key: 'outdoor', disabled: false }
        ]
      },
      pit: {
        value: -1,
        options: [
          { key: 'normal', disabled: false },
          { key: 'pitless', disabled: false },
          { key: 'shallow', disabled: false }
        ]
      }
    },

    /**
     * Selectable products in current step, if only one product is left,
     * the wizard is ready
     * {
     *   velino: true,
     *   tugela: false
     *   ...
     * }
     */
    products: {},

    /**
     * data properties of each step
     * this is also the order of data in lookup table!
     */
    stepConfig: {
      1: 'type',
      2: 'segment',
      3: 'traffic',
      4: 'inclination',
      5: 'distance',
      6: 'climate',
      7: 'pit',
      8: RESULT_STEP
    },

    /**
     * lookup table, taken from excel
     */
    lookup: [
      [ 'escalator',   'airport',       'high',     null,         null,    null,     null,     'tugela' ],
      [ 'escalator',   'airport',       'regular',  null,         null,    null,     null,     'velino' ],
      [ 'escalator',   'entertainment',  null,      null,         null,    null,     null,     'velino' ],
      [ 'escalator',   'hospital',       null,      null,         null,    null,     null,     'velino' ],
      [ 'escalator',   'hotel',          null,      null,         null,    null,     null,     'velino' ],
      [ 'escalator',   'office',         null,      null,         null,    null,     null,     'velino' ],
      [ 'escalator',   'railway',       'high',     null,         null,    null,     null,     'tugela' ],
      [ 'escalator',   'railway',       'peak',     null,         null,    null,     null,     'victoria' ],
      [ 'escalator',   'retail',         null,      null,         null,    null,     null,     'velino' ],
      [ 'movingwalk',  'airport',        null,     'inclined',    null,    null,     null,     'orinoco' ],
      [ 'movingwalk',  'airport',        null,     'horizontal', 'long',   null,     null,     'orinocohorizontal' ],
      [ 'movingwalk',  'airport',        null,     'horizontal', 'short', 'indoor', 'normal',  'orinocohorizontal' ],
      [ 'movingwalk',  'airport',        null,     'horizontal', 'short', 'outdoor',  null,    'orinocohorizontal' ],
      [ 'movingwalk',  'airport',        null,     'horizontal', 'short', 'indoor', 'pitless', 'iwalk' ],
      [ 'movingwalk',  'airport',        null,     'horizontal', 'short', 'indoor', 'shallow', 'iwalk' ],
      [ 'movingwalk',  'entertainment',  null,     'inclined',    null,    null,     null,     'orinoco' ],
      [ 'movingwalk',  'entertainment',  null,     'horizontal', 'long',   null,     null,     'orinocohorizontal' ],
      [ 'movingwalk',  'entertainment',  null,     'horizontal', 'short', 'indoor', 'normal',  'orinocohorizontal' ],
      [ 'movingwalk',  'entertainment',  null,     'horizontal', 'short', 'outdoor',  null,    'orinocohorizontal' ],
      [ 'movingwalk',  'entertainment',  null,     'horizontal', 'short', 'indoor', 'pitless', 'iwalk' ],
      [ 'movingwalk',  'entertainment',  null,     'horizontal', 'short', 'indoor', 'shallow', 'iwalk' ],
      [ 'movingwalk',  'hospital',       null,     'inclined',    null,    null,     null,     'orinoco' ],
      [ 'movingwalk',  'hospital',       null,     'horizontal', 'long',   null,     null,     'orinocohorizontal' ],
      [ 'movingwalk',  'hospital',       null,     'horizontal', 'short', 'indoor', 'normal',  'orinocohorizontal' ],
      [ 'movingwalk',  'hospital',       null,     'horizontal', 'short', 'outdoor', null,     'orinocohorizontal' ],
      [ 'movingwalk',  'hospital',       null,     'horizontal', 'short', 'indoor', 'pitless', 'iwalk' ],
      [ 'movingwalk',  'hospital',       null,     'horizontal', 'short', 'indoor', 'shallow', 'iwalk' ],
      [ 'movingwalk',  'hotel',          null,     'inclined',    null,    null,     null,     'orinoco' ],
      [ 'movingwalk',  'hotel',          null,     'horizontal', 'long',   null,     null,     'orinocohorizontal' ],
      [ 'movingwalk',  'hotel',          null,     'horizontal', 'short', 'indoor', 'normal',  'orinocohorizontal' ],
      [ 'movingwalk',  'hotel',          null,     'horizontal', 'short', 'outdoor', null,     'orinocohorizontal' ],
      [ 'movingwalk',  'hotel',          null,     'horizontal', 'short', 'indoor', 'pitless', 'iwalk' ],
      [ 'movingwalk',  'hotel',          null,     'horizontal', 'short', 'indoor', 'shallow', 'iwalk' ],
      [ 'movingwalk',  'office',         null,     'inclined',    null,    null,     null,     'orinoco' ],
      [ 'movingwalk',  'office',         null,     'horizontal', 'long',   null,     null,     'orinocohorizontal' ],
      [ 'movingwalk',  'office',         null,     'horizontal', 'short', 'indoor', 'normal',  'orinocohorizontal' ],
      [ 'movingwalk',  'office',         null,     'horizontal', 'short', 'outdoor', null,     'orinocohorizontal' ],
      [ 'movingwalk',  'office',         null,     'horizontal', 'short', 'indoor', 'pitless', 'iwalk' ],
      [ 'movingwalk',  'office',         null,     'horizontal', 'short', 'indoor', 'shallow', 'iwalk' ],
      [ 'movingwalk',  'railway',        null,     'horizontal', 'short', 'indoor', 'pitless', 'iwalk' ],
      [ 'movingwalk',  'railway',        null,     'inclined',    null,    null,     null,     'orinoco' ],
      [ 'movingwalk',  'railway',        null,     'horizontal', 'long',   null,     null,     'orinocohorizontal' ],
      [ 'movingwalk',  'railway',        null,     'horizontal', 'short', 'outdoor', null,     'orinocohorizontal' ],
      [ 'movingwalk',  'railway',        null,     'horizontal', 'short', 'indoor', 'shallow', 'iwalk' ],
      [ 'movingwalk',  'railway',        null,     'horizontal', 'short', 'indoor', 'normal',  'orinocohorizontal' ],
      [ 'movingwalk',  'retail',         null,     'inclined',    null,    null,     null,     'orinoco' ],
      [ 'movingwalk',  'retail',         null,     'horizontal', 'long',   null,     null,     'orinocohorizontal' ],
      [ 'movingwalk',  'retail',         null,     'horizontal', 'short', 'indoor', 'normal',  'orinocohorizontal' ],
      [ 'movingwalk',  'retail',         null,     'horizontal', 'short', 'outdoor', null,     'orinocohorizontal' ],
      [ 'movingwalk',  'retail',         null,     'horizontal', 'short', 'indoor', 'pitless', 'iwalk' ],
      [ 'movingwalk',  'retail',         null,     'horizontal', 'short', 'indoor', 'shallow', 'iwalk' ]
    ]
  },

  /*
  |--------------------------------------------------------------------------
  | Getters
  |--------------------------------------------------------------------------
  */

  getters: {

    /**
     * @param {Object} state
     * @return {Integer}
     */
    currentStep: (state) => {
      return fn.toInteger(state.steps[(state.steps.length - 1)])
    },

    /**
     * @param {Object} state
     * @return {String}
     */
    currentStepSlug: (state, getters) => {
      if (getters.isResultStep) {
        return 'result'
      } else {
        return 'step-' + getters.currentStep
      }
    },

    /**
     * @param {Object} state
     * @return {Integer}
     */
    currentStepCount: (state) => {
      return state.steps.length
    },

    /**
     * total steps, always reacting on the current wizard selection,
     * may change from step to step
     * @param {Object} state
     * @param {Object} getters
     * @return {Integer}
     */
    totalStepCount: (state, getters) => {
      return state.remaining + getters.currentStepCount
    },

    /**
     * get the field of the current step
     * @param {Object} state
     * @param {Object} getters
     * @return {String}
     */
    currentField: (state, getters) => {
      var step = getters.currentStep
      return state.stepConfig[step]
    },

    /**
     * checks, if current step is result step
     * @param {Object} state
     * @param {Object} getters
     * @return {Boolean}
     */
    isResultStep: (state, getters) => {
      var resultStep
      fn.each(state.stepConfig, (field, step) => {
        if (field === RESULT_STEP) {
          resultStep = step
        }
      })
      return (getters.currentStep === fn.toInteger(resultStep)) && getters.resultProduct
    },

    /**
     * return the result product, if unique
     * @param {Object} state
     * @return {String} or {Boolean}
     */
    resultProduct: (state) => {
      var res = []
      fn.each(state.products, (available, product) => {
        if (available) {
          res.push(product)
        }
      })
      if (res.length === 1) {
        return res[0]
      } else {
        return false // error, should not happen if lookup table is defined correct
      }
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Mutations
  |--------------------------------------------------------------------------
  */

  mutations: {

    /**
     * Init a step an reset all data of following steps
     * @param {Object} state 
     * @param {boolean} reset, true will init complete configurator
     */
    initStep (state, reset) {
      if (reset) {
        state.steps = [ 1 ]
        state.product = null
        fn.each(state.products, (value, key) => {
          state.products[key] = true
        })
      }
      var currentStep = fn.toInteger(state.steps[(state.steps.length - 1)])
      fn.each(state.stepConfig, (field, key) => {
        if (field !== RESULT_STEP && key >= currentStep && field !== null) {
          state.data[field].value = -1

          // don't reset options of current step, only following steps
          if (key > currentStep) {
            if (fn.has(state.data[field], 'options')) {
              fn.each(state.data[field].options, (option) => {
                option.disabled = false
              })
            }
          }
        }
      })
    },

    /**
     * Set the next step
     * @param {Object} state 
     * @param {Integer} step 
     */
    setNextStep (state, step) {
      if (fn.has(state.stepConfig, step)) {
        state.steps.push(fn.toInteger(step))
      }
    },

    /**
     * Remove last step from state.steps
     * @param {Object} state 
     */
    setPrevStep (state) {
      var currentStep = fn.toInteger(state.steps[(state.steps.length - 1)])
      if (currentStep > 1) {
        state.steps.pop()
      }
    },

    /**
     * store in state.data, regardless of step
     * @param {Object} state
     * @param {Object} params, { prop: value, [prop: value] }
     */
    setData (state, params) {
      fn.each(params, (value, field) => {
        if (fn.has(state.data, field)) {
          state.data[field].value = value
        }
      })
    },

    /**
     * Set disabled-property of options
     * @param {Object} state
     * @param {Object} params, { field: foo, options: bar }
     */
    setOptions (state, params) {
      if (fn.has(state.data, params.field) && fn.has(state.data[params.field], 'options')) {
        fn.each(state.data[params.field].options, (option) => {
          option.disabled = (params.options.indexOf(option.key) < 0)
        })
      }
    },

    /**
     * Set availability boolean of each product
     * @param {Object} state
     * @param {Array} availProducts, available Products
     */
    setProducts (state, availProducts) {
      fn.each(state.products, (value, key) => {
        state.products[key] = (availProducts.indexOf(key) >= 0)
      })
    },

    /**
     * Set remaining steps from current step on
     * @param {Object} state
     * @param {Integer} count
     */
    setRemainingSteps (state, count) {
      state.remaining = count
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Actions
  |--------------------------------------------------------------------------
  */

  actions: {

    /**
     * Init Wizard, goto step 1
     */
    init ({ state, commit, dispatch, rootGetters }) {
      state.products = rootGetters['products/available']('object')
      commit('initStep', true)
      dispatch('updateWizard').then(() => {
        state.init = true
      })
    },

    /**
     * store date of a step an updateWizard the next step
     * @param {Object} params, data to store fot the step
     */
    nextStep ({ commit, dispatch }, params) {
      commit('setData', params)
      dispatch('updateWizard').then((step) => {
        commit('setNextStep', step)
        commit('initStep')
      })
    },
    
    /**
    * go back to previous step
    */
    prevStep ({ commit, dispatch }) {
      commit('setPrevStep')
      commit('initStep')
      dispatch('updateWizard')
    },

    /**
     * uses lookup table to determine the next step, sets available options and products
     * @return {Promise}
     */
    updateWizard ({ state, commit }) {
      var col,
          step,
          key,
          value,
          row,
          lookup,
          filtered, 
          availProducts,
          availOptions,
          productCol,
          options,
          remainingsSteps,
          steps = [],
          keys = []

      fn.each(state.stepConfig, (field, step) => {
        steps.push(step)
        keys.push(field)
      })
      lookup = fn.clone(state.lookup)
      productCol = steps.length - 1
      for(col = 0; col < keys.length; col++) {
        key = keys[col]
        step = col + 1

        // error, break
        if (lookup.length === 0) {
          col = 0
          break
        }

        // get all unique availOptions from the column
        availOptions = []
        for(row = 0; row < lookup.length; row++) {
          if (availOptions.indexOf(lookup[row][col]) < 0) {
            availOptions.push(lookup[row][col])
          }
        }

        // get all unique availProducts
        availProducts = []
        for(row = 0; row < lookup.length; row++) {
          if (availProducts.indexOf(lookup[row][productCol]) < 0) {
            availProducts.push(lookup[row][productCol])
          }
        }

        // only one product left => ready
        if (availProducts.length === 1) {
          col = productCol
          step = col + 1
          break
        }

        if (key === RESULT_STEP) {
          break
        }

        // value for this row is set and valid => filter the table
        value = state.data[key].value
        if (value !== -1 && availOptions.indexOf(value) >= 0) {
          filtered = []
          for(row = 0; row < lookup.length; row++) {
            if (value === lookup[row][col]) {
              filtered.push(lookup[row])
            }
          }

          // next loop, work with filtered
          lookup = filtered
        }

        // check if more than 1 option exists => this is the next step
        else if (availOptions.length > 1) {
          break
        }
      }

      remainingsSteps = 0
      for(col; col < productCol; col++) {
        options = []
        for(row = 0; row < lookup.length; row++) {
          if (options.indexOf(lookup[row][col]) < 0) {
            options.push(lookup[row][col])
          }
        }
        if (options.length > 1) {
          remainingsSteps++
        }
      }

      // set result values
      commit('setOptions', {
        field: state.stepConfig[step],
        options: availOptions
      })
      commit('setProducts', availProducts)
      commit('setRemainingSteps', remainingsSteps)
      return Promise.resolve(step)
    }
  }
}

export default wizardStore