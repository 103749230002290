import Vue from 'vue'

const cookieStore = {
  namespaced: true,

  /*
  |--------------------------------------------------------------------------
  | State
  |--------------------------------------------------------------------------
  */
  state: {
    name: 'data',
    valid: 30, // days
    length: 0, // count of nodes in cookie
    consent: 0, // 0 = unknown, show dialog, 1 = permission, -1 = no permission
    data: {}
  },

  /*
  |--------------------------------------------------------------------------
  | Getters
  |--------------------------------------------------------------------------
  */

  getters: {
    get: (state) => (key) => {
      if (fn.has(state.data, key)) {
        return state.data[key]
      }
    },
    showConsentDialog: (state) => {
      return state.consent === 0 && state.length > 0
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Actions
  |--------------------------------------------------------------------------
  */

  actions: {

    // no reactivity here on state.data properties! This is not needed,
    // because cookie values are not for constant use in application,
    // it's a simple store away and read it on next site visit
    set ({ state, commit, dispatch }, params) {
      if (!fn.isObject(params)) {
        return
      }
      fn.each(params, (value, key) => {
        state.data[key] = value
      })
      state.length = Object.keys(state.data).length
      dispatch('setCookie')
    },
    setConsent ({ state, dispatch }, param) {
      state.consent = fn.isTrue(param) ? 1 : -1
      dispatch('setCookie')
    },
    setCookie({ state }) {
      if (!state.consent) {
        return
      }
      var expires = new Date()
      expires.setDate(expires.getDate() + state.valid)
      var cookie = [
        state.name, '=', JSON.stringify(state.data),
        '; path=/',
        '; expires=', expires.toUTCString(),
        //'; SameSite=None', doesn't seem to work
        //'; Secure',
        ';'
      ].join('')
      document.cookie = cookie
    }
  }
}

var res = document.cookie.match(new RegExp(cookieStore.state.name + '=([^;]+)'))
if (res) {
  cookieStore.state.data = JSON.parse(res[1])
  cookieStore.state.consent = 1
}

export default cookieStore