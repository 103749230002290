import Vue from 'vue'

const loaderService = class {

  /**
   * Load all components of folder /elements
   */
  loadGlobalElements () {

    // nor variables here, term must be statically analyzable!!!
    var BaseElements = require.context('@/components', true, /^\.\/(base|layout)\/.*\.(vue|js)$/)
    BaseElements.keys().forEach((path) => {
      var Component = BaseElements(path)
      Component = (Component.default || Component)
      Vue.component(fn.pascalCase(Component.name), Component)
    })
  }
}

export default new loaderService()
