import Vue from 'vue'
import Vuex from 'vuex'

import rootStore from '@/stores/root'
import cookieStore from '@/stores/cookie'
import cacheStore from '@/stores/cache'
import productsStore from '@/stores/products'
import projectStore from '@/stores/project'
import wizardStore from '@/stores/wizard'

Vue.use(Vuex)

const StoreService = new Vuex.Store(rootStore)

// attention: module name must not exist in root.state
StoreService.registerModule('cookie', cookieStore)
StoreService.registerModule('cache', cacheStore)
StoreService.registerModule('products', productsStore)
StoreService.registerModule('project', projectStore)
StoreService.registerModule('wizard', wizardStore)

export default StoreService