<template>
  <div class="sections-elem">
    <template v-for="(section, i) in sections">

      <!-- Headlines, Copy -->
      <row-elem
        v-if="isText(section)"
        :key="key(i)"
        class="section copy">
          <text-elem
            :content="section.text"
            component="col-elem"
            class="text" />
      </row-elem>

      <!-- Images -->
      <row-elem
        v-if="isImages(section)"
        :key="key(i)"
        :class="imagesClass(section)"
        class="section images is-box">
          <col-elem>
            <image-elem
              :file="section.images"
              size="sections.banner" />
          </col-elem>
      </row-elem>
    </template>
  </div>
</template>

<script>
export default {
  name: 'sections-elem',
  props: {
    content: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {}
  },
  computed: {
    sections () {
      var res = []
      var textKey = null
      fn.each(this.content, (section, key) => {
        
        // put h1 (h2) and copy together
        if (this.isHeadline(section) || this.isCopy(section)) {
          if (textKey !== null) {
            res[textKey].text.push(section)
          } else {
            res.push({
              fieldset: 'text',
              text: [ section ]
            })
            textKey = res.length - 1
          }
        }

        // the rest
        else {
          res.push(section)
          textKey = null
        }
      })
      return res
    }
  },
  methods: {
    key (...keys) {
      return keys.join('-')
    },

    // original sections
    isHeadline (section) {
      return section.fieldset === 'headline'
    },
    isCopy (section) {
      return section.fieldset === 'copy'
    },
    isImages (section) {
      return section.fieldset === 'images' && this.hasImage(section)
    },

    // grouped sections
    isText (section) {
      return section.fieldset === 'text'
    },
    hasImage (section) {
      return fn.isObject(section.images) && fn.isString(section.images.value)
    },
    imagesTitle (section) {
      if (fn.has(section, 'subheadline') && fn.isString(section.subheadline)) {
        return section.subheadline
      }
      return ''
    },
    sectionClass (section) {
      if (fn.has(section, 'cssclass') && fn.isString(section.cssclass)) {
        return section.cssclass
      }
    },
    imagesClass (section) {
      var res = this.sectionClass(section)
      if (section.display === 'slider') {
        res += ' is-slider'
      }
      return res
    },
    linkClass (section) {
      if (section.display === 'buttons') {
        return 'button is-outline'
      }
    }
  }
}
</script>

<style lang="sass">
.sections-elem
  .section
    margin-top: m(2)
    &:first-child
      margin-top: 0

+md
  .sections-elem

+sm
  .sections-elem
    .section
      margin-top: m(3)
      &:first-child
        margin-top: 0

+xs
  .sections-elem
    .section
      margin-top: m(2)
</style>