<template>
  <segment-elem class="product-segment">
    <container-elem class="product-select">
      <svg-elem name="sprite-escalator-round" />
      <ul class="escalators">
        <li
          v-for="product in escalators"
          :key="product"
          :class="{
            'is-active': currentProduct === product
          }">
            <link-elem :link="{ params: { product: product, step: 'step-1' }}" :replace="true">
              {{ $store.state.site.products[product] }}
            </link-elem>
        </li>
      </ul>
      <svg-elem name="sprite-movingwalk-round" />
      <ul class="movingwalks">
        <li
          v-for="product in movingwalks"
          :key="product"
          :class="{
            'is-active': currentProduct === product
          }">
            <link-elem :link="{ params: { product: product, step: 'step-1' }}" :replace="true">
              {{ $store.state.site.products[product] }}
            </link-elem>
        </li>
      </ul>
    </container-elem>
  </segment-elem>
</template>

<script>
import Store from '@/services/Store'
import Router from '@/services/Router'

export default {
  name: 'product-segment',
  computed: {
    currentProduct () {
      return this.$store.getters['project/product']
    },
    escalators () {
      return this.$store.getters['products/available']('array', 'escalator')
    },
    movingwalks () {
      return this.$store.getters['products/available']('array', 'movingwalk')
    }
  }
}
</script>

<style lang="sass">
.product-segment
  background-color: white()
  .product-select
    display: flex
    align-items: center
    .svg-elem
      fill: $primary-color
      width: m(7)
    ul
      display: flex
      li
        margin: 0 m(1.5)
        .link
          +font('medium', 'semibold')
          color: $primary-color
        &.is-active
          .link
            text-decoration: underline
      &.escalators
        margin-right: m(4)
      &.movingwalks
        //margin-right: - m(2)
  .actions
    border-top: 2px solid grey(6)

  +sm
  .product-segment
    .product-select
      display: none
</style>